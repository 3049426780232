import { API_URL } from "../config"
import { getToken } from "../util/validation"


const token = JSON.parse(getToken);

export async function inviteRenewalMember(formData, menuList){

    try {
        let data = {
            'formData': formData,
            'menuList': menuList,
            'role': 'RMEMBER'
        }

        const response = await fetch(API_URL + 'adminpanel/invite_admin/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        })

        if (response.status === 200) {
            return true
        }
        return false
    } catch (error) {
        return false
    }
}

export async function insertMemberTagging(admin_email,tags){

    try {
        const email = localStorage.getItem('user');
        
        let data = {
            'email': email,
            'admin_email': admin_email,
            'tags': tags
        }

        const response = await fetch(API_URL + 'adminpanel/add_member_tagging/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        })

        if (response.status === 200) {
            return true
        }
        return false
    } catch (error) {
        return false
    }
}


export async function getMyDomain(){

    try {

        const email = localStorage.getItem('user')
        var data = { 
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_my_domain/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }

}
