import { Outlet } from "react-router-dom";
import Div from "../components/Div";
import NavbarComponent from "../components/NavbarComponent";
import Sidebar from "../components/Sidebar"
// import { getUserMenuItem } from "../http/MenuHttp";
import { useState } from "react";

export default function Home() {
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const handleToggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    return (
        <>
            <Div className="container-scroller">
                <NavbarComponent onToggleSidebar={handleToggleSidebar} />
                <Div className={`container-fluid page-body-wrapper ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                    <Sidebar isAdmin='/home' isOpen={isSidebarOpen}/>

                    <Div className={`main-panel ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                        <Div className="content-wrapper">

                            <Outlet />
                            
                        </Div>
                    </Div>
                </Div>
            </Div>
        </>
    )
}

export async function menuLoader() {

    // const email = localStorage.getItem('user');
    const menu = JSON.parse(localStorage.getItem('menu'))
    return menu
    // return getUserMenuItem(email);
}