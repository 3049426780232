import Div from "./Div";
import Input from "./Input";
import FormButton from "./FormButton";
import { createPortal } from "react-dom";
import Select from "./Select";
import { status } from '../util/enum';
import Form from "./Form";
import { updateDomainStatus } from '../http/DomainHttp'

export default function EditDomainModal({ updateState, domain_name, handleClicked, ...props }) {


    const state = status.status.map((state) =>
        state.id !== domain_name.status ?
            <option key={state.id}
                name={state.id}
                value={state.id}>
                {state.value}</option>
            : null)

    function handleClose() {
        handleClicked(false);
    }

    async function handleUpdateStatus(event) {
        event.preventDefault();
        const statusFD = new FormData(event.target)

        const data = Object.fromEntries(statusFD.entries())
        console.log(!data.domain_status)
        console.log(data.domain_status)
        if ((!data.domain_status)) {

            handleClicked(false)
        }
        else {
            const response = await updateDomainStatus(domain_name.domain_id, data.domain_status, domain_name.domain_name)
            console.log(response)
            if (!response.error) {
                handleClicked(false)
                updateState()
            }
            else {
                alert(response.message)
            }
        }

    }

    return (
        <>
            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1', maxWidth: '75%' }}>

                        <Div className="form-group">
                            Edit Domain
                        </Div>
                        <Form onSubmit={handleUpdateStatus}>
                            <Div className="form-group">
                                <Input text=''
                                    type="text" className="form-control" placeholder="Enter Domain Name" value={domain_name.domain_name} disabled />
                            </Div>

                            <Div className="form-group">
                                <Select name='domain_status' options={state} defaultValue={domain_name.status} requied />

                            </Div>

                            <Div className="form-group">
                                <Div className='row'>
                                    <Div className='col-sm-6'>
                                        <FormButton type='submit' style={{ margin: '2px' }} className="btn btn-primary submit-btn btn-block">Edit Domain</FormButton>
                                    </Div>
                                    <Div className='col-sm-6'>
                                        <FormButton type='button' style={{ margin: '2px' }} onClick={handleClose} className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                                    </Div>
                                </Div>

                                <Div className='row'>
                                    <Div className='col-sm-12'>
                                        {/* <p style={{color:'red',marginLeft: '15px'}}> OTP Does Not Match</p>  */}
                                    </Div>
                                </Div>
                            </Div>
                        </Form>
                    </dialog>

                </Div>
                , document.getElementById('modal'))

            }
        </>
    )

}