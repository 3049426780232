import { API_URL } from "../config"
import { getToken } from "../util/validation";

const token = JSON.parse(getToken);

export async function addLicense(domainName) {

    try {
        const email = localStorage.getItem('user')
        var data = {
            'domainName': domainName,
            'email': email
        }

        const response = await fetch(API_URL + 'add_domain/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });

        return response

    } catch (error) {
        return false
    }
}

export async function getDomainDetails() {

    try {
        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'get_user_domain/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {
            return response.json()
        }
        else {
            return false
        }
    } catch (error) {
        return false
    }


}

export async function getGoogleDomainDetails(domain_id) {
    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'domain_id': domain_id
        }

        const response = await fetch(API_URL + 'adminpanel/get_google_domain/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });


        if (response.status === 200) {
            return response.json()
        }
        else {
            return false
        }
        
    } catch (error) {
        return false
    }
}

export async function getPendingDomains() {

    try {
        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_pending_domain/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });

        const responseJson = await response.json()

        if (responseJson.error) {
            return false
        }
        else {
            return responseJson
        }
    }
    catch (error) {
        return false;
    }

}

export async function setPendingDomainStatus(domain_id, status, domain_name) {

    try {

        const email = localStorage.getItem('user')

        const data = {
            'domain_id': domain_id,
            'status': status,
            'email': email,
            'domain_name': domain_name
        }

        const response = await fetch(API_URL + 'adminpanel/set_pending_domain_status/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {

            return response.json()
        }
    } catch (error) {
        return false
    }

}

export async function getAllDomains() {
    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_all_domain/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {

            return response.json()
        }
        else {
            return false
        }
    } catch (error) {
        return false
    }

}

export async function updateDomainStatus(domain_id, status, domain_name) {
    try {

        const email = localStorage.getItem('user');
        const data = {
            'email': email,
            'status': status,
            'domain_id': domain_id,
            'domain_name': domain_name,
        }

        const response = await fetch(API_URL + 'adminpanel/update_domain_status/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });

        return response.json()
    } catch (error) {
        return false
    }

}