import CanvasJSReact from '@canvasjs/react-charts'
import { useState, useEffect } from "react";
import { getYearlyRenewalHistory } from '../http/RenewalHttp';

const CanvasJSChart = CanvasJSReact.CanvasJSChart
// const updateInterval = 5000;

export default function RenewalHistory() {

    const [dataPoints, setDataPoints] = useState([]);

    const [cpuUsage, setCpuUsage] = useState("CPU Usage");

    const getData = async () => {
        try {
            const response = await getYearlyRenewalHistory()
            console.log(response)
            const formattedData = response.domain.map(item => ({
                label: new Date(item.date).toLocaleDateString(), // X-axis as date
                y: item.count  // Y-axis as count
            }));
            setDataPoints(formattedData);
            setCpuUsage(`Renewal History`);

        } catch (error) {

        }
    }


    useEffect(() => {
        // const interval = setInterval( async () => {        
        getData()
        // }, updateInterval);

        // return () => clearInterval(interval);
    }, []);



    const options = {
        theme: "dark2",
        title: {
            text: cpuUsage
        },
        axisX: {
            title: "Date",
        },
        axisY: {
            title: "Number of Renewal",
            includeZero: true,
            // suffix: "%",
            // maximum: 100
        },
        data: [{
            type: "column",
            // yValueFormatString: "#,###'%'",
            // indexLabel: "{y}",
            dataPoints: dataPoints // Populated by API data
        }]
    };


    return <>
        <CanvasJSChart options={options} />
    </>
}