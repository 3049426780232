import { API_URL } from "../config"
import { getToken } from "../util/validation";

const token = JSON.parse(getToken);
export async function getShivaamiPortal(){
    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_shivaami_portal/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }
}