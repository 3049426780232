import { Stack, Pagination } from "@mui/material"
import Div from "../../components/Div";
import Input from "../../components/Input";
import { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import FormButton from "../../components/FormButton";
import { getRenewalData, getFollowUpComments, updateSegmentsHttp, updateZoneHttp } from "../../http/RenewalHttp";
import FollowupHistoryModal from "../../components/FollowupHistoryModal";
import AddCommentModal from "../../components/AddCommentModal";
import Select from "../../components/Select";
import SendNotificationModal from "../../components/SendNotificationModal";
import { segments, zones } from '../../util/enum';
// import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";

export default function GetRenewalData() {

    const [customerList, setCustomerList] = useState([]);
    // const [isAscending, setIsAscending] = useState(true);
    const [initailCustomerList, setInitialCustomerList] = useState([])
    const [followupHistory, setFollowupHistory] = useState(false);
    const [sendNotification, setSendNotification] = useState(false);
    const [addComment, setAddComment] = useState(false);
    const [search, setSearch] = useState('')
    const [month, setMonth] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    // const [sortColumn, setSortColumn] = useState(null);
    // const [sortOrder, setSortOrder] = useState("asc");


    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = customerList.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(customerList.length / itemsPerPage);

    // Change page
    const paginate = (event, value) => {
        setCurrentPage(value);
    };

    const zone = zones.zone.map((zone) =>
        zone.id !== currentItems ?
            <option key={zone.id}
                name={zone.id}
                value={zone.id}>
                {zone.value}</option>
            : null);

    const state = segments.segment.map((state) =>
        state.id !== currentItems ?
            <option key={state.id}
                name={state.id}
                value={state.id}>
                {state.value}</option>
            : null)

    

    const getAllCustomers = async () => {
        const response = await getRenewalData()
        if (response) {
            console.log(response)
            setCustomerList(response.customer)
            setInitialCustomerList(response.customer)
        } else {
            // window.location.href = '/error'
            alert('Could not fetch data. Please contact your Administrator')
        }
    }

    useEffect(() => {
        getAllCustomers()
    }, [])


    async function handleFollowUp(renewal_id) {
        const followUpCommentResponse = await getFollowUpComments(renewal_id)
        // if (followUpCommentResponse){
        const followComments = {
            'renewal_id': renewal_id,
            'followupComments': followUpCommentResponse ? followUpCommentResponse.followup_comment : []
        }
        setFollowupHistory(followComments)

    }

    function handleSearchCustomer(event) {

        const search_item = event.target.value;
       
        if (month) {
            console.log(month)
            const filteredList = initailCustomerList.filter((customer) => {

                if ((customer && customer.domain_name)) {
                    // eslint-disable-next-line
                    const [month_item, day, year] = customer.renewal_date.split('-') 
                    const formattedDate = `${year}-${month_item}`
                    
                    return formattedDate.includes(month)

                }
                else {
                    return false
                }
            }).filter((customer) => {

                if ((customer && customer.renewal_date)) {
                    return customer.domain_name.toLowerCase().includes(search_item.toLowerCase());
                }
                else {
                    return false
                }
            });

            setCustomerList(filteredList)
            setSearch(search_item)
        }
        else {
            console.log(search_item)
            const filteredList = initailCustomerList.filter((customer) => {

                if ((customer && customer.domain_name)) {

                    return customer.domain_name.toLowerCase().includes(search_item.toLowerCase());
                }
                else {
                    return false
                }
            });
            setCustomerList(filteredList);
            setSearch(search_item)
        }

    }

    const handleUpdateSelect = async (renewal_id, field, value) => {
        setCustomerList(prevList =>
            prevList.map(customer =>
                customer.renewal_id === renewal_id ? { ...customer, [field]: value } : customer
            )
        );

        if (field === 'zone') {
            // updateZone
            const zoneResponse = await updateZoneHttp(renewal_id, value)
            if (zoneResponse) {

            }
            else {
                alert('Failed to Update Zone. Please try refreshing the page')
            }
        }
        else if (field === 'segments') {
            // update Segment

            const segmentResponse = await updateSegmentsHttp(renewal_id, value)
            if (segmentResponse) {

            }
            else {
                alert('Failed to Update Segment. Please try refreshing the page')
            }
        }
    };

    function handleSendNotification(domain_name, renewal_id, subscription_id, renewal_date) {
        const notifyData = {
            'domain_name': domain_name,
            'renewal_id': renewal_id,
            'subscription_id': subscription_id,
            'renewal_date': renewal_date
        }
        setSendNotification(notifyData)
    }

    function handleAddComment(renewal_id) {

        setAddComment(renewal_id);

    }

    function handleMonthChange(event) {
        const filterMonth = event.target.value

        if (search) {
            const filteredList = initailCustomerList.filter((customer) => {

                if ((customer && customer.domain_name)) {
                    return customer.domain_name.toLowerCase().includes(search.toLowerCase());
                }
                else {
                    return false
                }
            }).filter((customer) => {

                if ((customer && customer.renewal_date)) {
                    console.log(customer.domain_name)
                    // eslint-disable-next-line
                    const [month, day, year] = customer.renewal_date.split('-')
                    const formattedDate = `${year}-${month}`
                    // console.log(day)
                    return formattedDate.includes(filterMonth)

                    // return customer.renewal_date.includes(filterMonth)
                }
                else {
                    return false
                }
            });

            setCustomerList(filteredList)
            setMonth(filterMonth)
        }
        else {

            const filteredList = initailCustomerList.filter((customer) => {

                if ((customer && customer.domain_name)) {
                    // eslint-disable-next-line
                    const [month, day, year] = customer.renewal_date.split('-')
                    const formattedDate = `${year}-${month}`
                    // console.log(day)
                    return formattedDate.includes(filterMonth)
                }
                else {
                    return false
                }
            });
            console.log(filteredList)
            setCustomerList(filteredList);
            setMonth(filterMonth)
        }
    }

    function handleExport() {

        if (currentItems) {
            const array = [Object.keys(currentItems[0])].concat(customerList);

            const csv = array.map(row => Object.values(row).toString()).join('\n');
            // console.log(csv)
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('href', url);
            a.setAttribute('download', 'data.csv');
            a.click();
        }
        else {
            return null;
        }
    }

    function handleStartDateChange(event) {
        console.log(event.target.value)
        const start_date = event.target.value
        console.log(start_date)
        setStartDate(start_date)

    }

    function handleEndDateChange(event) {
        // console.log(event.target.value)
        const end_date = event.target.value
        console.log(startDate)
        const start = new Date(startDate);
        const end = new Date(end_date);
        setEndDate(end_date)
        console.log(end)
        if (startDate) {

            const filteredList = initailCustomerList.filter((customer) => {
                if ((customer && customer.renewal_date)) {
                    // console.log(customer.domain_name)
                    const renewalDate = new Date(customer.renewal_date);
                    // const end = new Date(end_date);
                    // console.log(startDate)
                    return renewalDate >= start && renewalDate <= end;
                }
                else {
                    return false
                }
            });
            console.log(filteredList)
            setCustomerList(filteredList)

        }

    }

    function handleReset() {
        setSearch('');
        setMonth('');
        setEndDate(new Date());
        setStartDate(new Date());
        setCustomerList(initailCustomerList)
    }

    const handleSort = (column) => {

        // let sortedList = [...initailCustomerList];

        // if (sortColumn === column) {
        //     sortedList.reverse(); // Toggle the sorting order
        //     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        // } else {
        //     sortedList.sort((a, b) => {
        //         if (a[column] < b[column]) return -1;
        //         if (a[column] > b[column]) return 1;
        //         return 0;
        //     });
        //     setSortColumn(column);
        //     setSortOrder("asc");
        // }
        // console.log(sortedList)
        // setInitialCustomerList(sortedList);
    };

    return (
        <>
            {sendNotification ? <SendNotificationModal notify={sendNotification} show={true.toString()} open handleClicked={setSendNotification} /> : null}
            {addComment ? <AddCommentModal comments={addComment} show={true.toString()} open handleClicked={setAddComment} /> : null}
            {followupHistory ? <FollowupHistoryModal history={followupHistory} show={true.toString()} open handleClicked={setFollowupHistory} /> : null}
            <PageTitle title='Renewal Data' />
            <Div className="col-md-12">
                <Div className="page-header-toolbar">

                    <Div className="btn-group toolbar-item" role="group" >
                        <Input type="text" name="email" id="email" value={search}
                            onChange={handleSearchCustomer}
                        />

                        <Input type='month' name='month' id='month' style={{ marginLeft: '50px' }} value={month}
                            onChange={handleMonthChange}
                        />

                        <Input type='date' name='start_date' id='start_date' style={{ marginLeft: '50px' }} value={startDate}
                            onChange={handleStartDateChange}
                        />

                        <Input type='date' name='end_date' id='end_date' style={{ marginLeft: '50px' }} value={endDate}
                            onChange={(e) => { if (e.target.value) handleEndDateChange(e) }}
                        />

                        <FormButton
                            type='button' style={{ marginLeft: '50px', marginTop: '8px' }}
                            className="btn btn-primary submit-btn btn-block"
                            onClick={handleReset}
                        >Reset</FormButton>


                        <FormButton
                            type='button' style={{ marginLeft: '50px' }}
                            className="btn btn-primary submit-btn btn-block"
                            onClick={handleExport}
                        >Export</FormButton>

                    </Div>

                    <Div className="filter-wrapper">
                    </Div>
                    {/* <span>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                <BsFilter />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Active')}
                                >
                                    Active
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Rejected')}
                                >
                                    Rejected
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Suspended')}
                                >
                                    Suspended
                                </Dropdown.Item>

                            </Dropdown.Menu>

                        </Dropdown>

                    </span> */}
                </Div>
            </Div>

            <Div className="col-md-12 margin_top">
                {currentItems ? <table className="table" border="1">
                    <thead>
                        <tr>
                            <th>Customer Domain</th>
                            <th>Plan</th>
                            <th>Status</th>
                            <th>License</th>
                            <th>Users</th>
                            <th>Sku Id</th>
                            <th className="sortable-header"
                                onClick={() => handleSort("renewal_date")}
                            >Renewal Date
                                {/* {sortColumn === "renewal_date" ? (
                                    sortOrder === "asc" ? (
                                        <FaSortUp className="sort-icon" />
                                    ) : (
                                        <FaSortDown className="sort-icon" />
                                    )
                                ) : (
                                    <FaSort className="sort-icon" /> // Default sort icon
                                )} */}
                            </th>
                            <th>Zone</th>
                            <th>Google Renewal Date</th>
                            <th>Price</th>
                            <th>Segments</th>
                            <th>Add Comment</th>
                            <th>Follow up History</th>
                            <th>Follow Up Comments</th>
                            <th>Highlighted Case Flag</th>
                            <th>Confirmation Status</th>
                            <th>Aggrement Status</th>
                            <th>Price Per User</th>
                            <th>Special Comment</th>
                            <th>Task Status</th>
                            <th>Renewed License</th>
                            <th>Extension Date</th>
                            <th>Assigned</th>
                            {/*<th>Email</th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody id="tableBody">
                        {currentItems.map((customer) =>

                            <tr key={customer.renewal_id}>
                                <td>
                                    {customer.domain_name}
                                    {/* <button onClick={()=>handlecustomerClick(customer.customer_id)} className="btn btn-link ">{customer.customer_name}</button> */}
                                </td>
                                <td>{customer.plan_name}</td>
                                <td>{customer.status}</td>
                                <td>{customer.licensed_seats}</td>
                                <td>{customer.number_of_seats}</td>
                                <td>{customer.sku_name}</td>
                                <td>{customer.renewal_date}</td>
                                <td>
                                    <Select name='zone' options={zone} defaultValue={customer.zone}
                                        onChange={(e) => handleUpdateSelect(customer.renewal_id, 'zone', e.target.value)}

                                        style={{ width: 'auto' }} />
                                    {/* {customer.zone} */}
                                </td>
                                <td>{customer.google_renewal_data}</td>
                                <td>{customer.price}</td>
                                <td>
                                    <Select name='user_status' options={state} defaultValue={customer.segments}
                                        onChange={(e) => handleUpdateSelect(customer.renewal_id, 'segments', e.target.value)}

                                        style={{ width: 'auto' }} />
                                    {/* {customer.segments} */}
                                </td>
                                <td>
                                    <FormButton
                                        type='button'
                                        className="btn btn-primary submit-btn btn-block"
                                        onClick={() => handleAddComment(customer.renewal_id)}
                                    >Add Comments</FormButton>
                                </td>

                                <td>
                                    <FormButton
                                        type='button'
                                        className="btn btn-primary submit-btn btn-block"
                                        onClick={() => handleFollowUp(customer.renewal_id)}
                                    >Follow up History</FormButton>
                                </td>
                                <td>{customer.followup_comments}</td>
                                <td></td>
                                <td>{customer.confirmation_status}</td>
                                <td>
                                    {customer.agreement_status}
                                </td>
                                <td>{customer.price_per_user}</td>
                                <td>{customer.special_comments}</td>
                                <td>{customer.task_status ? customer.task_status : customer.reason}</td>
                                <td>{customer.renewed_lic}</td>
                                <td>{customer.extension_date}</td>
                                <td>{customer.assign}</td>
                                {/* <td>{customer.email}</td> */}
                                <td>
                                    <FormButton
                                        type='button'
                                        className="btn btn-link"
                                        onClick={() => handleSendNotification(customer.domain_name, customer.renewal_id, customer.subscription_id, customer.renewal_date)}
                                    >Send Notification</FormButton>
                                </td>
                            </tr>
                        )

                        }
                    </tbody>
                </table> :
                    <>
                        <p style={{ marginTop: '30px' }}>Not Domain Present or Could not fetch Domains</p>
                    </>}

                {currentItems.length > 0 ?
                    <Stack spacing={2}>
                        <Pagination count={totalPages} color="primary" page={currentPage} onChange={paginate}>

                        </Pagination>

                    </Stack> : null
                }
            </Div>

        </>
    )
}