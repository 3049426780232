import Input from '../components/Input';
import Header from '../components/Header';
import FormButton from '../components/FormButton';
import Div from '../components/Div';
import Form from '../components/Form';
// import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { forgetPassword } from '../http/VerifyHttp';
import { sendOtp } from '../http/VerifyHttp';
import { useState } from 'react';

export default function ForgetPassword() {

    //  Handle Login Error
    const [loginError, setLoginError] = useState(false);

    const [OTPClicked, setOTPClicked] = useState(false);

    const [inputEmail, handleInputEmail] = useState('');

    const [resetPassword, setResetPassword] = useState(false);

    const handleEmailOnChange = (event) => {
        setLoginError(false)
        handleInputEmail(event.target.value)
    }

    async function handleSendOTP() {
        if (inputEmail) {

            const otpResponse = await sendOtp(inputEmail)
            if (otpResponse) {
                setOTPClicked(true);
            }
            else {
                setOTPClicked(false)
            }
        }
        else {
            setLoginError('Email cannot be Blank')
        }

    }

    function handleSubmit(event) {
        event.preventDefault()
        const forgetFD = new FormData(event.target)
        console.log(resetPassword)
        const data = Object.fromEntries(forgetFD.entries())
        console.log(data)
        // const otpResponse = verifyOTP(data.otp, data.email)
        const forgotPassword = forgetPassword(data.otp, data.email,data.password , data.confirm_password)
        console.log(forgotPassword)
        if (forgotPassword) {
            setResetPassword(true)
        }
        else {
            alert('Failed to Send Otp. Please Try again..!!')
        }
    }
    return (
        <>
            <Div className="container-scroller">
                <Div className="container-fluid page-body-wrapper full-page-wrapper">
                    <Div className="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
                        <Div className="row w-100">
                            <Div className="col-lg-4 mx-auto text-center">
                                <Header title='Recover Your Password' />

                                <p style={{ color: 'white' }}>Enter your email address and an OTP will be emailed to you.</p>
                                <Div className="auto-form-wrapper">
                                    <Form onSubmit={handleSubmit}>
                                        <Div className="form-group">
                                            <Input type="email" className="form-control"
                                                name='email' onChange={handleEmailOnChange} placeholder="Email" />
                                        </Div>
                                        <Div className="form-group">
                                            <FormButton className="btn btn-primary submit-btn btn-block"
                                                onClick={handleSendOTP} type='button'>Send OTP</FormButton>
                                        </Div>

                                        

                                        {OTPClicked ?
                                            <Div className="form-group">
                                                <Input text='otp' name='otp' type="text" className="form-control" placeholder="Enter OTP" />
                                            </Div>
                                            : null}
                                        {/* <Div className="form-group">

                                            {OTPClicked ?
                                                <FormButton onClick={''} className="btn btn-primary submit-btn btn-block">Verify OTP</FormButton> :
                                                null
                                            }
                                        </Div> */}


                                        {OTPClicked ?
                                            <>
                                                <Div className="form-group">
                                                    <Input type='password' text='password' name='password'  className="form-control" placeholder="Enter New Password" />

                                                </Div>

                                                <Div className="form-group">
                                                    <Input text='password' name='confirm_password' type="text" className="form-control" placeholder="Enter Confirm Password" />

                                                </Div>
                                            </>
                                            : null}

                                        {OTPClicked ?
                                            <>
                                                <FormButton type='submit' className="btn btn-primary submit-btn btn-block">Reset Password</FormButton>
                                            </> : null

                                        }

                                        <Div className="text-block text-center my-3">
                                            <span className="text-small font-weight-semibold">Already have and account ?</span>
                                            <Link to="/" className="text-black text-small">Login</Link>
                                        </Div>

                                    </Form>

                                    {loginError ? <Div className="form-group">
                                        <span style={{ color: 'red' }}>{loginError}</span>
                                    </Div> : null}

                                </Div>

                            </Div>

                        </Div>
                    </Div>
                </Div>
            </Div>
        </>
    )

}