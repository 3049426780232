import Div from "../../components/Div"
import RenewalHistory from "../../components/RenewalHistory"
import RenewalStatus from "../../components/RenewalStatus"


export default function RenewalDashboard() {

    return <>
        <Div>
            <Div style={{ margin: '50px' }}>
                <RenewalHistory />
            </Div>
            <Div style={{ margin: '50px' }}>
                <RenewalStatus />
            </Div>
        </Div>
    </>
}