import Div from "../../components/Div"
import PageTitle from "../../components/PageTitle"
import FormButton from "../../components/FormButton"
import Input from "../../components/Input"
import { Stack, Pagination } from "@mui/material"
import { useState, useEffect } from "react"
import { getAllRenewalTeam } from "../../http/RenewalHttp"
import AddMemberModal from "../../components/AddMemberModal"
import ManageTagsModal from "../../components/ManageTagsModal"

export default function ManageRenewalTeam() {

    const [customerList, setCustomerList] = useState([]);
    // const [isAscending, setIsAscending] = useState(true);
    const [initailCustomerList, setInitialCustomerList] = useState([])

    const [addMember, setAddMember] = useState(false)

    const [manageTeam, setManageTeam] = useState(false)

    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = customerList.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(customerList.length / itemsPerPage);

    // Change page
    const paginate = (event, value) => {
        setCurrentPage(value);
    };

    const getAllTeamMember = async () => {
        const response = await getAllRenewalTeam()
        if (response) {
            console.log(response)
            setCustomerList(response.customer)
            setInitialCustomerList(response.customer)
        } else {
            // window.location.href = '/error'
            // alert('Could not fetch data. Please contact your Administrator')
        }
    }

    useEffect(() => {
        getAllTeamMember()
    }, [])


    function handleAddMemberModal() {
        setAddMember(true)
    }

    function handleSearchCustomer(event) {

        const search_item = event.target.value;

        console.log(search_item)
        const filteredList = initailCustomerList.filter((customer) => {

            if ((customer && customer.admin_name)) {

                return customer.admin_name.toLowerCase().includes(search_item.toLowerCase());
            }
            else {
                return false
            }
        });
        setCustomerList(filteredList);
        setSearch(search_item)
    }

    function handleManageRenewalTeam(customer){


        const list = customer.tags.split(',');

        const data = {
            'segment': list[0],
            'zone' : list[1],
            'manager' : list[2],
            'email': customer.admin_email,
            'name': customer.admin_name,
            'id': customer.admin_id,
            'phone': customer.phone_number
        }

        setManageTeam(data)
    }

    return <>
        {manageTeam ? <ManageTagsModal show={true.toString()} open handleClicked={setManageTeam} tag={manageTeam} />: null}
        {addMember ? <AddMemberModal show={true.toString()} open handleClicked={setAddMember} /> : null}
        <PageTitle title='Manage Renewal Team' />

        <Div className="col-md-12">
            <Div className="page-header-toolbar">

                <Div className="btn-group toolbar-item" role="group" >

                    <Input type="text" name="email" id="email" placeholder='Enter Team Name'
                        value={search}
                        onChange={handleSearchCustomer}
                    />

                    <FormButton
                        type='button' 
                        style={{ marginLeft: '50px' }}
                        className="btn btn-primary submit-btn btn-block"
                        onClick={handleAddMemberModal}
                    >Add Team Member</FormButton>

                </Div>

            </Div>
        </Div>

        <Div className="col-md-12 margin_top">
            {currentItems.length > 0 ? <table className="table" border="1">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Number</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody id="tableBody">
                    {initailCustomerList.map((customer) =>

                        <tr key={customer.admin_id}>
                            <td>
                                {customer.admin_name}
                            </td>
                            <td>{customer.admin_email}</td>
                            <td>{customer.phone_number}</td>
                            <td>
                                <FormButton type='button' 
                            className="btn btn-primary submit-btn btn-block"
                            onClick = {() =>handleManageRenewalTeam(customer)}
                            >
                                    Manage Member
                                </FormButton>
                            </td>
                        </tr>
                    )

                    }
                </tbody>
            </table> :
                <>
                    <p style={{ marginTop: '30px' }}>No Team Present or Could not fetch Team</p>
                </>}

            {currentItems.length > 0 ?
                <Stack spacing={2}>
                    <Pagination count={totalPages} color="primary" page={currentPage} onChange={paginate}>

                    </Pagination>

                </Stack> : null
            }
        </Div>

    </>
}