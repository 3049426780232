import { useEffect, useState } from "react"
import Div from "../components/Div"
import FormButton from "../components/FormButton"
import PageTitle from "../components/PageTitle"
import { getDomainDetails } from "../http/DomainHttp"
import { GoogleSubscriptionModal } from "../components/GoogleSubscriptionModal"


export default function AddLicense() {

    const [domainList, setDomainList] = useState([]);
    const [modalClicked, handleModalClicked] = useState(false)
    const [filterDomain, setFilterDomain] = useState([])
    const [initialDomainList, setInitialDomainList] = useState([])

    useEffect(() => {
        async function getDomain() {
            const response = await getDomainDetails()
            if (response) {
                const domain = await response.domains
                setDomainList(domain)
                setInitialDomainList(domain)
            }
        }
        getDomain()

    }, [])

    async function handleAddLicense(domain_name) {

        const domainSubscription = domainList.filter((domain) => {
            if (domain.domain_name === domain_name) {
                return domain
            } else {
                return false
            }
        }
        )
        setFilterDomain(domainSubscription)
        handleModalClicked(domain_name)
    }

    function handleSearchDomain(event){
        const search = event.target.value;
        const filteredList = initialDomainList.filter((domain) => {
            
            if (domain && domain.domain_name) {
                
                return domain.domain_name.toLowerCase().includes(search.toLowerCase());
            } 
            else{
                return false
            }
        });

        setDomainList(filteredList)

    }

    return (
        <>

            {modalClicked ? <GoogleSubscriptionModal show={true.toString()} domain_name={modalClicked} googleLicense={filterDomain} open handleClicked={handleModalClicked} /> : null}

            <PageTitle title='Add License' />

            <Div className="btn-group toolbar-item" role="group"  style={{marginLeft:'15px'}}>
                        <input type="text" name="domain" onChange={handleSearchDomain} id="domain" className="" />
                    </Div>

            <Div className="col-md-12" style = {{marginTop : '15px'}}>

                {domainList.length > 0 ?
                    <Div >
                        <table className="table" border="1">

                            <thead>
                                <tr>
                                    <th>Domain Name</th>
                                    <th>Subscription</th>
                                    <th>Renewal Date</th>
                                    <th>Action

                                    </th>
                                </tr>
                            </thead>
                            <tbody id="tableBody">
                                {domainList.map((domain) => {
                                    let activeCounter = 0
                                    let suspendCounter = 0
                                    domain.subscription.map((subs) => {
                                        if (subs.status === 'ACTIVE') {
                                            activeCounter += 1
                                        }
                                        else {
                                            suspendCounter += 1
                                        }
                                        return ''
                                    })
                                    const count = suspendCounter > 0 ? activeCounter + " Active " + suspendCounter + " Suspended" : activeCounter + ' Active'

                                    return <tr key={domain.domain_id}>
                                        <td>{domain.domain_name}</td>
                                        <td> {count} </td>
                                        <td>{domain.renewal_date}</td>
                                        <td>
                                            <FormButton type='button' onClick={() => handleAddLicense(domain.domain_name)}
                                                className="btn btn-primary submit-btn btn-block">View Subscription</FormButton>
                                        </td>
                                    </tr>
                                }
                                )}
                            </tbody>
                        </table>
                    </Div> :
                    <>
                        <p>No Subscription Found for this Domain </p>
                    </>}


            </Div>
        </>
    )
}