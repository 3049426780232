import { useEffect, useState } from "react"
import { getShivaamiPortal } from "../../http/ShivaamiHttp";
import Div from "../../components/Div";
import { Pagination, Stack } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Input from "../../components/Input";
// import UpdateRenewalCustomerModal from "../../components/EditRenewalCustomerModal";


export default function ShivaamiPortal() {

    const [customerList, setCustomerList] = useState([]);
    // const [isAscending, setIsAscending] = useState(true);
    // const [modalClicked, setModalClicked] = useState(false)
    const [initailCustomerList, setInitialCustomerList] = useState([])
    // const [googleCustomer, setGoogleCustomer] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = customerList.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(customerList.length / itemsPerPage);

    // Change page
    const paginate = (event, value) => {
        setCurrentPage(value);
    };

    const getShivaamiDetail = async () => {
        const response = await getShivaamiPortal()
        setCustomerList(response.customer)
        setInitialCustomerList(response.customer)
    }

    useEffect(() => {
        getShivaamiDetail()
    }, [])

    function handleSearchCustomer(event) {

        const search = event.target.value;
        const filteredList = initailCustomerList.filter((customer) => {

            if ((customer && customer.customer_domain) || (customer.sku_name)) {

                return customer.customer_domain.toLowerCase().includes(search.toLowerCase()) ||
                    customer.sku_name.toLowerCase().includes(search.toLowerCase());
            }
            else {
                return false
            }
        });

        setCustomerList(filteredList);

    }

    return (
        <>
            {/* {modalClicked ? <UpdateRenewalCustomerModal customer={modalClicked} show={true.toString()} open handleClicked={setModalClicked} /> : null} */}
            <PageTitle title='Shivaami Portal' />
            <Div className="col-md-12">
                <Div className="page-header-toolbar">

                    <Div className="btn-group toolbar-item" role="group" >
                        <Input type="text" name="email" id="email"
                            onChange={handleSearchCustomer}
                        />
                    </Div>

                    <Div className="filter-wrapper">
                    </Div>
                    {/* <span>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                <BsFilter />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Active')}
                                >
                                    Active
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Rejected')}
                                >
                                    Rejected
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Suspended')}
                                >
                                    Suspended
                                </Dropdown.Item>

                            </Dropdown.Menu>

                        </Dropdown>

                    </span> */}
                </Div>
            </Div>

            <Div className="col-md-12 margin_top">
                {/* {currentPage} */}
                {currentItems ?
                    <>
                        <table className="table" border="1">
                            <thead>
                                <tr>
                                    <th>Domain Name</th>
                                    <th>Sku Name</th>
                                    <th>Licensed Seats</th>
                                    <th>Max Seats</th>
                                    <th>Number of Seats</th>
                                    <th>Plan Name</th>
                                    <th>Status</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Time
                                        {/* <button onClick={sortData} variant="primary">
                                    <span className="ml-2">
                                        {isAscending ? '↓' : '↑'}
                                    </span>
                                </button> */}
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="tableBody">
                                {currentItems.map((customer) =>

                                    <tr key={customer.subscription_id}>
                                        <td>
                                            {customer.customer_domain}
                                            {/* <button onClick={()=>handlecustomerClick(customer.customer_id)} className="btn btn-link ">{customer.customer_name}</button> */}
                                        </td>
                                        <td>{customer.sku_name}</td>
                                        <td>{customer.licensed_seats}</td>
                                        <td>{customer.max_seats}</td>
                                        <td>{customer.number_of_seats}</td>
                                        <td>{customer.plan_name}</td>
                                        <td>{customer.status}</td>
                                        <td>{customer.startdate}</td>
                                        <td>{customer.enddate}</td>
                                        <td>{customer.created_on}</td>

                                    </tr>
                                )

                                }
                            </tbody>
                        </table>

                    </>
                    :
                    <>
                        <p style={{ marginTop : '30px' }}>Not Domain Present or Could not fetch Domains</p>
                    </>}

                {currentItems.length > 0 ?
                    <Stack spacing={2}>
                        <Pagination count={totalPages} color="primary" page={currentPage} onChange={paginate}>

                        </Pagination>
                    </Stack>
                    : null}
            </Div>

        </>
    )
}