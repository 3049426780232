import CanvasJSReact from '@canvasjs/react-charts'
import { useState, useEffect } from "react";
import { getRenewalStatus } from '../http/RenewalHttp';

const CanvasJSChart = CanvasJSReact.CanvasJSChart


export default function RenewalStatus() {

    const [dataPoints, setDataPoints] = useState([]);

    const [cpuUsage, setCpuUsage] = useState("CPU Usage");

    const getData = async () => {
        try {
            const response = await getRenewalStatus()
            console.log(response)
            const formattedData = response.domain.map(item => ({
                label: item.status, // X-axis as date
                y: item.count  // Y-axis as count
            }));
            setDataPoints(formattedData);
            setCpuUsage(`Renewal Status`);

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // const interval = setInterval( async () => {        
        getData()
        // }, updateInterval);

        // return () => clearInterval(interval);
    }, []);



    const options = {
        theme: "dark2",
        title: {
            text: cpuUsage
        },
        axisX: {
            title: "Status",
        },
        axisY: {
            title: "Number of Renewal",
            includeZero: true,
            // suffix: "%",
            // maximum: 100
        },
        data: [{
            type: "column",
            // yValueFormatString: "#,###'%'",
            // indexLabel: "{y}",
            dataPoints: dataPoints // Populated by API data
        }]
    };

    return <>
            <CanvasJSChart options={options} />
    </>
}