import Div from "./Div"
import Input from "./Input"
import FormButton from "./FormButton"
import { createPortal } from "react-dom"
import Form from "./Form";
import { addDepartment } from "../http/DepartmentHttp";

export default function DepartmentModal({ getAllDepartment, handleClicked, setHttpSubmit, ...props }) {

    async function handleAddDepartment(event){
        event.preventDefault();

        const registerFD = new FormData(event.target)

        const data = Object.fromEntries(registerFD.entries())

        const departmentResponse = await addDepartment(data.department_name)
        if (departmentResponse){
            getAllDepartment()
            handleClicked(false)
        }
        else{
            alert('Failed to add department. Please try again..!')
        }
    }
    
    return (
        <>

            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1', width: '35%' }}>
                        <Form onSubmit={handleAddDepartment}>
                        <Div className="form-group">
                            <Input text='' type="text" name='department_name' className="form-control" placeholder="Enter Department Name" />
                        </Div>
                        <Div className="form-group">
                            <Div className='row'>
                                <Div className='col-sm-6'>
                                    <FormButton type='submit' className="btn btn-primary submit-btn btn-block" >Add Department</FormButton>
                                </Div>
                                <Div className='col-sm-6'>
                                    <FormButton type='button' className="btn btn-primary submit-btn btn-block" onClick={()=> handleClicked(false)}>Close</FormButton>
                                </Div>
                            </Div>
                        </Div>
                        </Form>
                    </dialog>
                </Div>
                , document.getElementById('modal'))
            }

        </>
    )
}