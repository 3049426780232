import Input from '../../components/Input';
import Header from '../../components/Header';
import FormButton from '../../components/FormButton';
import Div from '../../components/Div';
import Form from '../../components/Form';
import OTPModal from '../../components/OTPModal.js';
import Select from '../../components/Select.js';
import { Link } from 'react-router-dom';
import { checkPasswordMatch, checkPhoneNumber } from '../../util/validation.js'
import {sendOtp} from '../../http/VerifyHttp.js'
// import {registerAdminHttp, sendVoucher} from '../../http/AdminVerifyHttp.js'
import { useState } from 'react'
// import { API_URL } from "../../config";
import { useLoaderData } from 'react-router-dom';

export default function AdminRegister() {

    //Handle Button Click State
    const [OTPClicked, handleOTPClicked] = useState(false)

    //Set OTP verified
    const [OTPVerify, setOTPverified] = useState(false)

    // Handle Submit Button State
    const [verifyRegister, handleVerifyRegister] = useState(false)

    // Handle Email Input State
    const [inputEmail, handleInputEmail] = useState('')

    // Handle Voucher State

    // const [voucherInput, setVoucherInput] = useState(null)

    //Handle Submit State
    const [errorSubmit, handleErrorSubmit] = useState(false)

    // Get Loader Data
    const data = useLoaderData()

    const departments = data.department.map((department) => <option key={department.department_id}
     name = {department.department_id}
     value={department.department_id}>
        {department.department_name}</option>)

    // Hangdle Error Message State
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('')
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('')
    const [emailErrorMessage, setemailErrorMessage] = useState('')

    function handleOTP() {
        if (inputEmail) {
            setemailErrorMessage('')
            sendOtp(inputEmail)
            handleOTPClicked(true);
            handleErrorSubmit(false)
        }
        else {
            setemailErrorMessage("Email Id cannot be blank")
        }

    }

    const handleEmailOnChange = (event) => {
        handleErrorSubmit(false)
        setOTPverified(false)
        handleInputEmail(event.target.value)
    }

    function handlePhoneNumberLenght(event) {
        if (!checkPhoneNumber(event.target.value)) {
            setPhoneNumberErrorMessage('Invalid Phone Number')
            return
        }

        setPhoneNumberErrorMessage('')

    }

    async function handleVoucherClick(){
        // const response = await sendVoucher(inputEmail)
        // if (response) {
        //     setVoucherInput(<Div className="form-group"> 
        //     <Input name='voucher' text='' type="text" className="form-control" required placeholder="Voucher Code" /> 
        //     </Div>)
        // }
        // else{
        //     handleErrorSubmit('Admins Can only be from Shivaami')
        // }
    }

    async function handleSubmit(event) {

        event.preventDefault();

        const registerFD = new FormData(event.target)

        const data = Object.fromEntries(registerFD.entries())

        if (!checkPasswordMatch(data.password, data.confirmPassword)) {
            setConfirmPasswordErrorMessage('Password Does not Match !')
            return
        }
        setConfirmPasswordErrorMessage('')

        if (!checkPhoneNumber(data.phoneNumber.length)) {
            setPhoneNumberErrorMessage('Invalid Phone Number')
        }

        if(!data.voucher){
            handleErrorSubmit('Please Generate Voucher Code')
            return
        }
        handleErrorSubmit(false)
        setPhoneNumberErrorMessage('')

        // const registerResponse = await registerAdminHttp(data)

        // if (registerResponse.status === 200){
        //     window.location.href = 'adminpanel/'
        // }
        // else if(registerResponse.status === 403){
        //     handleErrorSubmit('Email Already Exists or In correct Voucher')
        //     setOTPverified(false)
        // }
        // else{
        //     handleErrorSubmit('Something went Wrong..!!')
        // }
    }

    return (
        <>
            <Div className="container-scroller">
                <Div className="container-fluid page-body-wrapper full-page-wrapper">
                    <Div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">

                        <Div className="row w-100">

                            <Div className="col-lg-12 text-center mb-5">
                                <Header title='Admin Register' />
                                <Div className="col-lg-4 mx-auto">
                                    <Div className="auto-form-wrapper">
                                        <Form onSubmit={handleSubmit}>
                                            <Div className="form-group">
                                                <Input name='userName' text='' type="text" className="form-control" required placeholder="Username" />
                                            </Div>

                                            <Div className="form-group">
                                                <Input onBlur={handlePhoneNumberLenght} name='phoneNumber' error={phoneNumberErrorMessage}
                                                    text='' type="text" className="form-control" required placeholder="Phone Number" />
                                            </Div>
                                            {OTPClicked ? <OTPModal show={true.toString()} open handleOTPClicked={handleOTPClicked} setOTPverified={setOTPverified}
                                                handleVerifyRegister={handleVerifyRegister} email={inputEmail} />
                                                : null}
                                            <Div className="form-group">
                                                <Div className="row">
                                                    <Div className="col-8">
                                                        <Input name='email' type="email" error={emailErrorMessage}
                                                            onChange={handleEmailOnChange} className="form-control" required placeholder="Email ID" />
                                                    </Div>
                                                    <Div className="col-4">
                                                        <FormButton type='button' className="btn btn-primary submit-btn btn-block"
                                                            onClick={handleOTP}> Send OTP</FormButton>
                                                    </Div>
                                                </Div>
                                            </Div>

                                            <Div className="form-group">
                                                <Select name='department' options = {departments} defaultValue = "Select Department"/>
                                            </Div>
                                            <Div className="form-group">
                                                <Input name='password' type="password" className="form-control" required placeholder="Password" />
                                            </Div>
                                            <Div className="form-group">
                                                <Input name='confirmPassword' error={confirmPasswordErrorMessage} type="password" className="form-control" required placeholder="Confirm Password" />
                                            </Div>

                                            <Div className="form-group">
                                            {OTPVerify ? <FormButton type='button' 
                                            className="btn btn-primary submit-btn btn-block" onClick={handleVoucherClick}>
                                                    Get Voucher</FormButton> : null}
                                            </Div>

                                            {/* {voucherInput} */}

                                            <Div className="form-group">
                                                {OTPVerify ? <FormButton type='submit' className="btn btn-primary submit-btn btn-block"
                                                    disabled={verifyRegister}>
                                                    Register</FormButton> : null}
                                            </Div>
                                            <Div className="form-group">
                                                {errorSubmit ? <span style={{ color: 'red' }}>{errorSubmit}</span>
                                                    : null}
                                            </Div>
                                            <Div className="text-block text-center my-3">
                                                <span className="text-small font-weight-semibold">Already a customer ?</span>
                                                <Link to="/adminpanel" className="text-black text-small">Login</Link>
                                            </Div>
                                        </Form>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </>
    )
}

export async function departmentLoader(){
   
}