import Div from "./Div";
import Input from "./Input";
import FormButton from "./FormButton";
import { createPortal } from "react-dom";
import Form from "./Form";
import { useState, useEffect } from "react";
import { getAdminMenuItem } from "../http/MenuHttp";
import { updateAdminDetails } from "../http/AdminVerifyHttp";
import AdminDelegationModal from "./AdminDelegationModal";



export default function EditAdminModal({ updateState, admin, handleClicked, ...props }) {

    const [updatedAdmin, setUpdatedAdmin] = useState(admin);
    const [menuItem, setMenu] = useState();
    const [delegateModal, setDelegateModal] = useState(false)


    useEffect(() => {
        const getMenu = async () => {
            try {
                let menuResponse = await getAdminMenuItem(admin.admin_email);
                menuResponse = await menuResponse.json()
                setMenu(menuResponse.menu);
               
            } catch (error) {
                window.alert("Failed to fetch Menu data. Please try again later.");
            }
        };

        getMenu();
    }, [admin.admin_email]);

    async function handleUpdateStatus(event) {
        event.preventDefault();
        const statusFD = new FormData(event.target)

        const data = Object.fromEntries(statusFD.entries())

        const EditAdminResponse = await updateAdminDetails(data, menuItem)
        if (EditAdminResponse) {
            handleClicked(false)
        } else {
            alert('Failed to Edit Admin Details, Please Contact Your Administrator')
        }
    }

    function handleChangeInput(event, field) {
        setUpdatedAdmin(admin[field] = event.target.value)
    }

    function handleClose() {
        handleClicked(false);
    }

    return (
        <>
        {delegateModal ? <AdminDelegationModal show={true.toString()} buttonText='Approve' open admin={admin} handleClicked={setDelegateModal}/> :null}
            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1' }}>

                        <Div className="form-group">
                            Edit Admin
                        </Div>
                        <Form onSubmit={handleUpdateStatus}>
                            <Div className="form-group">
                                <input text=''
                                    type="text" name='admin_id' onChange={(event) => handleChangeInput(event, 'admin_id')} value={admin.admin_id || updatedAdmin.admin_id} hidden />

                                <Input text=''
                                    type="text" onChange={(event) => handleChangeInput(event, 'admin_name')} name='admin_name' className="form-control" placeholder="Enter Admin Name" value={admin.admin_name || updatedAdmin.admin_name} />
                            </Div>

                            <Div className="form-group">
                                <Input text=''
                                    type="text" name='admin_email' onChange={(event) => handleChangeInput(event, 'admin_email')} className="form-control" placeholder="Enter Admin Emails" value={updatedAdmin.admin_email} disabled />
                            </Div>

                            <Div className="form-group">
                                <Input text=''
                                    type="text" name='phone_number' onChange={(event) => handleChangeInput(event, 'phone_number')} className="form-control" placeholder="Enter Company Name" value={updatedAdmin.phone_number} />
                            </Div>

                            <Div className="form-group">
                                <Div className='row'>
                                    <Div className='col-sm-4'>
                                        <FormButton type='submit' className="btn btn-primary submit-btn btn-block">Edit Admin</FormButton>
                                    </Div>
                                    <Div className='col-sm-4'>
                                        <FormButton type='button' onClick={()=> setDelegateModal(true)} className="btn btn-primary submit-btn btn-block">Edit Admin Delegation</FormButton>
                                    </Div>
                                    <Div className='col-sm-4'>
                                        <FormButton type='button' onClick={handleClose} className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                                    </Div>
                                </Div>

                                <Div className='row'>
                                    <Div className='col-sm-12'>
                                        {/* <p style={{color:'red',marginLeft: '15px'}}> OTP Does Not Match</p>  */}
                                    </Div>
                                </Div>
                            </Div>
                        </Form>
                    </dialog>

                </Div >
                , document.getElementById('modal'))

            }
        </>
    )
}