import { API_URL } from "../config"
import { getToken } from "../util/validation"

const token = JSON.parse(getToken);

export async function getRenewalData(){

    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_renewal_data/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }

}

export async function getFollowUpComments(renewal_id){

    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'renewal_id' : renewal_id
        }

        const response = await fetch(API_URL + 'adminpanel/get_followup_comment/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }

}

export async function getNotificationDataHttp(notify){

    try {
        console.log(notify)
        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'renewal_id' : notify.renewal_id,
            'subscription_id' : notify.subscription_id,
            'domain_name' : notify.domain_name
        }

        const response = await fetch(API_URL + 'adminpanel/get_zoho_notify_customer/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }

}

export async function sendNotification(customer, domain_name, subscription_id, renewal_date){

    try {
        // console.log(parseInt(subscription_id))
        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'customer': customer,
            'domain_name' : domain_name,
            'subscription_id' : subscription_id,
            'renewal_date' : renewal_date
        }

        const response = await fetch(API_URL + 'adminpanel/send_renewal_notification/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }

}

export async function insertFollowupCommentHttp(comment, renewal_id){

    try {
        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'comment': comment,
            'renewal_id' : renewal_id
        }

        const response = await fetch(API_URL + 'adminpanel/insert_followup_comment/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }
}

export async function updateSegmentsHttp(renewal_id, value){

    try {
        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'segments': value,
            'renewal_id' : renewal_id
        }

        const response = await fetch(API_URL + 'adminpanel/update_segments/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {
            
            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }
}

export async function updateZoneHttp(renewal_id, value){
    try {
        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'zone': value,
            'renewal_id' : renewal_id
        }

        const response = await fetch(API_URL + 'adminpanel/update_zone/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }
}

export async function insertComments(data, renewal_id){
    try {
        const email = localStorage.getItem('user')
        var payload = {
            'email': email,
            'formData':data,
            'renewal_id' : renewal_id
        }

        const response = await fetch(API_URL + 'adminpanel/insert_comments/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(payload),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }
}

export async function getMonthlyRenewalData(month) {
    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'month': month
        }

        const response = await fetch(API_URL + 'adminpanel/get_monthly_renewal_data/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }

}

export async function getYearlyRenewalHistory(){
    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_yearly_renewal_history/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }

}

export async function getRenewalStatus(){
    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_renewal_status_count/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }

}

export async function getAllRenewalTeam(){

    try {

        const email = localStorage.getItem('user')
        var data = { 
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_renewal_team/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }

}


export async function GetAllRenewalManagers(){

    try {

        const email = localStorage.getItem('user')
        var data = { 
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_renewal_team_manager/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }

}

