import Div from "./Div"
import FormButton from "./FormButton"
import Logo from '../assets/images/shivaami200.png'
import MiniLogo from '../assets/images/shivaami200logo.png'
import { Link } from "react-router-dom"
import { Navbar } from "react-bootstrap"
import { useState } from "react"
import ProfileModal from "./ProfileModal"


export default function NavbarComponent({ onToggleSidebar }) {

    const [modalClicked, setModalClicked] = useState(false);

    return (
        <>

            <Navbar className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
            // collapseOnSelect expand="lg"
            >

                <Div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
                    <Link className="navbar-brand brand-logo" to="/home">
                        <img src={Logo} alt="logo" /> </Link>
                    <Link className="navbar-brand brand-logo-mini" to="/home">
                        <img src={MiniLogo} alt="logo" /> </Link>
                </Div>
                <Div
                    className="navbar-menu-wrapper d-flex align-items-center"
                    style={{ justifyContent: 'end' }}
                >
                    <div className="dropdown">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown d-none d-xl-inline-block user-dropdown">
                                <button
                                    className="dropdown-item dropdown-toggle"
                                    id="UserDropdown"
                                    // href="#"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img className="img-xs rounded-circle" src={MiniLogo} alt="profileImage" />
                                </button>

                                <div
                                    className="dropdown-menu dropdown-menu-right navbar-dropdown"
                                    aria-labelledby="UserDropdown"
                                >

                                    <FormButton
                                        className="dropdown-item"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setModalClicked(true)}
                                    >
                                        My Profile
                                        <i className="dropdown-item-icon ti-dashboard"></i>
                                    </FormButton>

                                    <FormButton
                                        className="dropdown-item"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setModalClicked(true)}
                                    >
                                        Reset Password
                                        <i className="dropdown-item-icon ti-dashboard"></i>
                                    </FormButton>

                                    {/* <a
                                    className="dropdown-item"
                                    // onClick={handleSignOut}
                                    style={{ cursor: "pointer" }}
                                >
                                    Sign Out<i className="dropdown-item-icon ti-power-off"></i>
                                </a> */}
                                </div>
                            </li>
                        </ul>

                    </div>

                    <FormButton
                        className="navbar-toggle collapsed navbar-toggler-right d-lg-none align-self-center"
                        //  navbar-toggler-right d-lg-none align-self-center"
                        style={{ border: '0px' }}
                        type="button" data-toggle="offcanvas" onClick={onToggleSidebar}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </FormButton>
                </Div>

            </Navbar>

            {modalClicked ? <ProfileModal  setModalClicked = {setModalClicked} show={true.toString()} open /> : null }
            
        </>
    )
}