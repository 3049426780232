import Div from '../components/Div';

export default function Input({ error,text, ...props }) {
    return (
        <>
            {/* <label className="label">{text}</label> */}
            <Div className="input-group">
                <input {...props} />
                <Div className="input-group-append">
                    {/* <span className="input-group-text">
                        <i className="mdi mdi-check-circle-outline"></i>
                    </span> */}
                </Div>
            </Div>
            <p style={{color:'red'}}>{error}</p>
        </>
    )
}