import Div from "./Div";
import { useLoaderData } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import { getSessionUser } from "../util/validation";

export default function Sidebar({isAdmin, isOpen }) {

  const data = useLoaderData()

  const menu = data.map((menu) => <li key={menu.menu_id} className="nav-item">
  <a className="nav-link" href={isAdmin+"/"+menu.to_route}>
    <i className="menu-icon typcn typcn-document-text"></i>
    <span className="menu-title">{menu.menu_name}</span>
  </a>
</li>)

  return (
    <>

      <nav className={`sidebar sidebar-offcanvas ${isOpen ? 'open' : ''}`} id="sidebar" style={{height : 'auto'}}>
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a href="/" className="nav-link">

              <Div className="text-wrapper">
                <p className="profile-name">{getSessionUser}</p>
              </Div>
            </a>
          </li>

          <li className="nav-item nav-category">Main Menu</li>
          
           {menu}

          <li className="nav-item">
            <a className="nav-link" href='/logout'>
              <i className="menu-icon typcn typcn-document-text"></i>
              <span className="menu-title">Logout</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  )
}