import PageTitle from "../components/PageTitle"
import Div from "../components/Div"
import AddLicenseModal from "../components/AddLicenseModal"
import FormButton from "../components/FormButton"
import { useState, useEffect } from "react"
import { getDomainDetails } from "../http/DomainHttp"

export default function DomainList() {

    const [modalClicked, handleModalClicked] = useState(false)

    const [domainData, setDomainData] = useState([])

    useEffect(() => {
        const fetchDomainData = async () => {
            try {
                const response = await getDomainDetails();
                setDomainData(response.domains);
            } catch (error) {
                window.alert("Failed to fetch Domain data. Please try again later.");
            }
        };

        fetchDomainData();
    }, []);

    function handleAddLicense() {
        handleModalClicked(true)
    }

    return (
        <>
            {modalClicked ? <AddLicenseModal show={true.toString()} open handleClicked={handleModalClicked} /> : null}
            <PageTitle title='Mapped Domain' />

            <Div className="col-md-12">
                <Div className="page-header-toolbar">

                  
                    <Div className="filter-wrapper">
                    </Div>

                    <Div className="sort-wrapper">
                        <FormButton type="button" onClick={handleAddLicense} className="btn btn-primary toolbar-item">Add Domain</FormButton>
                    </Div>

                </Div>
            </Div>



            <Div className="row">
                <Div className="col-md-12">
                    <Div className="row">

                        <Div className="col-md-12 grid-margin margin_top">
                            <Div className="card">
                                <Div className="table-responsive">
                                    {domainData.length > 0 ?
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Domain Name</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {domainData.map((domain) => (
                                                    <tr key={domain.domain_id}>
                                                        <td>{domain.domain_name}</td>
                                        
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> : <p style={{margin: '1%'}}>No Domain Mapped to this User</p>
                                    }
                                </Div>
                            </Div>
                        </Div>



                    </Div>
                </Div>

            </Div>
        </>
    )

}