import { API_URL } from "../config"
import { getToken } from "../util/validation"

const token = JSON.parse(getToken);

export async function addGoogleLicense(subscription_id, customer_id, seats, plan_name){
    try {
        const email = localStorage.getItem('user')
        var payload = {
            'email': email,
            'subscription_id':subscription_id,
            'customer_id' : customer_id,
            'seats' : seats,
            'plan_name': plan_name
        }

        const response = await fetch(API_URL + 'add_google_license/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(payload),
        });
        
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }
}
