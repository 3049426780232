import { createPortal } from "react-dom"
import Div from "./Div";
import Input from "./Input";
import FormButton from "./FormButton";
import { useState } from "react";
import {addLicense} from '../http/DomainHttp'

function AddLicenseModal({handleClicked, ...props}) {

    const [inputDomain, setInputDomain] = useState()

    const [error, setError] = useState(false)

    function handleClose() {
        handleClicked(false);
    }

    async function handleAddLicense(){

        const domain=await addLicense(inputDomain)
        if (domain.status === 200){
            window.location.href = '/home/map_domain'
        }
        else if (domain.status === 403){
            setError('Domain Already Exists')
        }
        else if (domain.status === 500){
            setError('Failed to Add Domain')
        }
        else{
            alert('Failed to add License..!!')
        }

    }

    return (
        <>
            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1' , maxWidth:'75%'}}>
                    
                    <Div className="form-group">
                        Add License 
                    </Div>

                    <Div className="form-group">
                        <Input text='' onInput={(e) => setInputDomain(e.target.value)} type="text" className="form-control" placeholder="Enter Domain Name" />
                    </Div>
                   
                    <Div className="form-group">
                        <Div className='row'>
                            <Div className='col-sm-6'>
                                <FormButton type='button' style={{margin:'2px'}} onClick={handleAddLicense} className="btn btn-primary submit-btn btn-block">Add Domain</FormButton>
                            </Div>
                            <Div className='col-sm-6'>
                                <FormButton type='button'  onClick={handleClose} style={{margin:'2px'}} className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                            </Div>
                        </Div>
                       
                        <Div className='row'>
                            <Div className='col-sm-12'>
                              {error ?  <p style={{color:'red',marginLeft: '15px', marginTop:'4px'}}> {error}</p> : null }
                            </Div>
                        </Div>
                    </Div>

                    </dialog>

                </Div>
                , document.getElementById('modal'))

            }
        </>
    )
}

export default AddLicenseModal;