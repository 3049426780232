import { API_URL } from "../config";
import { getToken } from "../util/validation"

const token = JSON.parse(getToken);

export async function addCustomer(formData){
    try {
        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'formData': formData,
        }

        const response = await fetch(API_URL + 'adminpanel/add_customer/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {

            return response.json()
        }
        else {
            return false
        }
    } catch (error) {
        return false
    }
}

export async function getAllCustomer(){

    try {
        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_all_customer/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {

            return response.json()
        }
        else {
            return false
        }
    } catch (error) {
        return false
    }
}

export async function updateRenewalCustomer(formData){

    try {
        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'formData': formData,
        }

        const response = await fetch(API_URL + 'adminpanel/update_renewal_customer/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {

            return response.json()
        }
        else {
            return false
        }
    } catch (error) {
        return false
    }

}