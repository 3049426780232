import { createPortal } from "react-dom"
import Div from "./Div"
import Form from "./Form"
import FormButton from "./FormButton"
import { insertFollowupCommentHttp } from "../http/RenewalHttp"


export default function FollowupHistoryModal({ history, handleClicked, ...props }) {

    function handleClose() {
        handleClicked(false)
    }

    async function handleInsertComment(event){
        event.preventDefault();

        const registerFD = new FormData(event.target)

        const data = Object.fromEntries(registerFD.entries())
        console.log(data.comment, history.renewal_id);
        const followUpResponse = await insertFollowupCommentHttp(data.comment, history.renewal_id);
        if (followUpResponse){
            window.location.reload()
        }
    }

    return (
        <>
            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1', maxWidth: '100%' }}>

                        <Div className="form-group">
                            Follow Up Comments
                        </Div>

                        <Form onSubmit={handleInsertComment}>
                            <table className="table" border="1">
                                <thead>
                                    <tr>
                                        <th>Follow Up Comment</th>
                                        <th>Updated By</th>
                                        <th>Updated On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history ? history.followupComments.map((comment) =>
                                        <tr key={comment.comment_id}>
                                            <td>
                                                <Div className="form-group">
                                                    {comment.followup_comment}
                                                </Div>
                                            </td>
                                            <td>
                                                <Div className="form-group">
                                                    {comment.updated_by}
                                                </Div>

                                            </td>
                                            <Div className="form-group">
                                                {comment.updated_on}
                                            </Div>
                                        </tr>
                                    ) : 'No FollowUp History Present'

                                    }
                                    <tr>
                                        <td>
                                            <textarea name="comment"></textarea>
                                        </td>
                                        <td></td>
                                        <td>{new Date().toISOString()}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <Div className="form-group">
                                <Div className='row'>
                                    <Div className='col-sm-6'>
                                        <FormButton type='submit' style={{ margin: '2px' }} 
                                        // onClick={handleInsertComment}
                                            className="btn btn-primary submit-btn btn-block" >Insert Comment</FormButton>
                                    </Div>
                                    <Div className='col-sm-6'>
                                        <FormButton type='button' style={{ margin: '2px' }} onClick={handleClose}
                                            className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                                    </Div>
                                </Div>

                            </Div>

                        </Form>
                    </dialog>

                </Div>
                , document.getElementById('modal'))

            }
        </>
    )
}