import { API_URL } from "../config"
import { getToken } from "../util/validation";

const token = JSON.parse(getToken);

export async function getAllUsers() {

    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_all_users/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
            body: JSON.stringify(data),
        });
        console.log(token)
        if (response.status === 200) {

            return response.json()
        }
        else{
            
            return false
        }

    } catch (error) {
        return false
    }
}

export async function updateUserData(formData) {

    const email = localStorage.getItem('user')
    var data = {
        'email': email,
        'formData': formData
    }

    const response = await fetch(API_URL + 'adminpanel/update_user_data/', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.login_token,
        },
        body: JSON.stringify(data),
    });

    if (response.status === 200) {

        return response.json()
    }

}

export async function getPendingUsers() {

    try {
        const email = localStorage.getItem('user')

        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_pending_user/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        });

        const responseJson = await response.json()
        if (responseJson.error) {
            return false
        }
        return responseJson
    }
    catch (error) {
        // alert('Something Went wrong When feting data..!')
        return false
    }

}

export async function setPendingUserStatus(user_id, status) {

    const email = localStorage.getItem('user')

    var data = {
        'email': email,
        'user_id': user_id,
        'status': status
    }

    const response = await fetch(API_URL + 'adminpanel/set_pending_user/', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.login_token,
        },
        body: JSON.stringify(data),
    });

    if (response.status === 200) {

        return response.json()
    }
}

export async function editProfile(formData) {
    const email = localStorage.getItem('user')

    var data = {
        'email': email,
        'formData': formData
    }

    const response = await fetch(API_URL + 'edit_user/', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.login_token,
        },
        body: JSON.stringify(data),
    });

    if (response.status === 200) {

        return response.json()
    }
}
