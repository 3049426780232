import Div from "../../components/Div"
import PageTitle from "../../components/PageTitle"
import Form from "../../components/Form"
import Input from "../../components/Input"
import Select from "../../components/Select"
import { useEffect, useState } from "react"
import { getAllDepartment } from "../../http/DepartmentHttp"
import { getAllMenuItem } from "../../http/MenuHttp"
import { inviteAdmin } from "../../http/AdminVerifyHttp"
import FormButton from "../../components/FormButton"
import DepartmentModal from "../../components/DepartmentModal"
import Spinner from "../../components/Spinner"

export default function InviteAdmin() {

    const [departments, setDepatment] = useState([])
    const [menuItem, setMenu] = useState()
    const [selectList, setSelectList] = useState(new Set())
    const [departmentModal, setDepatmentModal] = useState(false)
    const [errorState, setErrorState] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(false)
        const getMenu = async () => {
            try {
                const menuResponse = await getAllMenuItem();

                setMenu(menuResponse.menu);

            } catch (error) {
                setErrorState(true)
            }
        };

        getMenu()
    }, [])

    useEffect(() => {
        const getDepartment = async () => {
            try {
                const response = await getAllDepartment();
                const department = response.department.map((department) => <option key={department.department_id}
                    name={department.department_id}
                    value={department.department_id}>
                    {department.department_name}</option>)

                setDepatment(department)

            } catch (error) {
                setErrorState(true)
            }
        };

        getDepartment()
    }, [])

    function handleMenuClick(menu_id, menu_name) {
        setSelectList((prev) => {
            const newClickedItems = new Set(prev);
            if (newClickedItems.has(menu_name)) {
                newClickedItems.delete(menu_name);
            } else {
                newClickedItems.add(menu_name);
            }
            return newClickedItems;
        })
    }

    function handleRemoveList(list) {
        setSelectList((prev) => {
            const newClickedItems = new Set(prev);
            if (newClickedItems.has(list)) {
                newClickedItems.delete(list)
            }
            return newClickedItems;
        });
    }

    function handleAddDepartment() {
        setDepatmentModal(true)
    }

    async function handleSubmit(event) {
        setIsLoading(true)
        event.preventDefault();

        const registerFD = new FormData(event.target)

        const data = Object.fromEntries(registerFD.entries())

        const filterList = menuItem.filter((menu) => {
            if (selectList.has(menu.menu_name)) {
                return menu
            } else {
                return false
            }
        })

        const menuIds = filterList.map((menu) => menu.menu_id)

        const response = await inviteAdmin(data, [...menuIds].join(','))
        if (response){
            window.location.reload();
            setIsLoading(false)
            return 
        }
        else{
            alert('Failed to Send invitation. Please try again...!!')
        }


    }

    return (
        <>

            {departmentModal ? <DepartmentModal show={true.toString()} getAllDepartment={getAllDepartment} open handleClicked={setDepatmentModal} /> : null}

            {isLoading ? <Spinner/> : null}
            <PageTitle title='Invite Admin' />
            {!errorState ?
                <Div className="col-lg-12 grid-margin stretch-card">
                    <Div className="card">
                        <Div className="card-body">

                            <Form className="form-sample" onSubmit={handleSubmit}>
                                <Div className="row">
                                    <Div className="col-md-5">
                                        <Div className="form-group row">
                                            <Input name='adminName' text='' type="text" className="form-control" required placeholder="Admin Name" />
                                        </Div>
                                    </Div>
                                    <Div className="col-md-2">

                                    </Div>
                                    <Div className="col-md-5">
                                        <Div className="form-group row">
                                            <Input name='adminEmail' text='' type="email" className="form-control" required placeholder="Admin Email" />
                                        </Div>
                                    </Div>

                                    <Div className="col-md-5">
                                        <Div className="form-group row">
                                            <Select name='department' options={departments} defaultValue="Select Department" />
                                        </Div>
                                    </Div>
                                    <Div className="col-md-2">

                                    </Div>
                                    <Div className="col-md-5">
                                        <Div className="form-group row">
                                            <Input name='adminPhoneNo' text='' type="text" className="form-control" required placeholder="Admin Phone No" />
                                        </Div>
                                    </Div>
                                    <Div
                                        className="scroll-container"
                                        style={{
                                            overflowY: "auto",
                                            height: "350px",
                                            width: "30%",
                                            border: "1px solid #000",
                                            padding: "10px",
                                            marginLeft: '12%'
                                        }}
                                    >
                                        <Div
                                            className="modules"
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                justifyContent: "center",
                                                gap: "10px",
                                                marginBottom: "20px",
                                                width: "80%",
                                                margin: "auto", // Center the .modules container
                                            }}
                                        >
                                            {menuItem ? menuItem.map((menu) =>
                                                <Div
                                                    key={menu.menu_id}
                                                    className="module"
                                                    style={{
                                                        border: `3px solid "#000"`,
                                                        padding: "10px",
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                        minWidth: "100px",
                                                        gap: "5px",
                                                        // color: clickedMenu.has(menu.menu_id) ? "green" : "inherit",
                                                        borderRadius: "30px", // Add this line to make the box rounded
                                                    }}
                                                    onClick={() => handleMenuClick(menu.menu_id, menu.menu_name)}
                                                >
                                                    {menu.menu_name}
                                                </Div>

                                            ) :
                                                <Div>Loading.....</Div>}
                                        </Div>
                                    </Div>

                                    <Div
                                        className="scroll-container"
                                        style={{
                                            overflowY: "auto",
                                            height: "350px",
                                            width: "30%",
                                            border: "1px solid #000",
                                            padding: "10px",
                                            marginLeft: '17%'
                                        }}
                                    >
                                        <Div
                                            className="modules"
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                justifyContent: "center",
                                                gap: "10px",
                                                marginBottom: "20px",
                                                width: "80%",
                                                margin: "auto", // Center the .modules container
                                            }}
                                        >
                                            <Div
                                                className="module"
                                                style={{
                                                    border: `3px solid "#000"`,
                                                    padding: "10px",
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                    minWidth: "100px",
                                                    gap: "5px",
                                                    borderRadius: "30px", // Add this line to make the box rounded
                                                }}
                                            >
                                                {selectList ? [...selectList].map((list, index) =>
                                                    <Div key={index}
                                                        style={{
                                                            border: `3px solid "#000"`,
                                                            padding: "10px",
                                                            textAlign: "center",
                                                            cursor: "pointer",
                                                            minWidth: "100px",
                                                            gap: "5px",
                                                            // color: clickedMenu.has(menu.menu_id) ? "green" : "inherit",
                                                            borderRadius: "30px", // Add this line to make the box rounded
                                                        }}
                                                        onClick={() => handleRemoveList(list)}>{list}</Div>
                                                ) : null}
                                            </Div>

                                        </Div>
                                    </Div>
                                </Div>
                                <br></br>

                                <FormButton type="submit" className="btn btn-primary">
                                    Send Invitation
                                </FormButton>
                                <FormButton type="button" onClick={handleAddDepartment} style={{ marginLeft: '5%' }} className="btn btn-primary">
                                    Add Department
                                </FormButton>
                            </Form>
                        </Div>
                    </Div>
                </Div> :
                <>
                    <p>Server Error. Try refreshing your Page</p>
                </>
            }

        </>
    )
}