import { Link } from "react-router-dom"

export default function Logout(){
    return (
        <>
        <div style={{display:"block", textAlign: "center", margin: "20%"}}>
              Thankyou For the Service with Shivaami
            
            <Link to='/'> Go to Home</Link>
        </div>
            
        </>
    )
}