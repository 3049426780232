import { useState, useEffect } from "react";
import { getAllUsers } from '../../http/UserHttp'
import PageTitle from '../../components/PageTitle'
import Div from "../../components/Div";
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Dropdown } from 'react-bootstrap'
import EditUserModal from "../../components/EditUserModal";
import Input from "../../components/Input";
import { Pagination, Stack } from "@mui/material";


export default function GetAllUser() {

    const [userList, setUserList] = useState([]);
    const [modalClicked, handleModalClicked] = useState(false)
    const [initailUserList, setInitialUserList] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = userList.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(userList.length / itemsPerPage);

     // Change page
     const paginate = (event, value) => {
        setCurrentPage(value);
      };

    const getUser = async () => {
        const response = await getAllUsers()
        if (response) {
            setUserList(response.users)
            setInitialUserList(response.users)
        }

    }
    useEffect(() => {

        getUser()

    }, []);

    function handleSearchUser(event) {

        const search = event.target.value;
        const filteredList = initailUserList.filter((user) => {
            if (user && user.user_email) {

                return user.user_email.toLowerCase().includes(search.toLowerCase());
            } else {
                return false;
            }
        });
        setUserList(filteredList);

    }

    function editUserModal(user) {

        handleModalClicked(user)
    }

    const renderActionButtons = (user) => {
        return (
            <div className="d-flex justify-content-center">
                <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                        <BsThreeDotsVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => editUserModal(user)} >
                            Edit
                        </Dropdown.Item>
                    </Dropdown.Menu>

                </Dropdown>
            </div>
        );
    };

    return (
        <>
            <PageTitle title='Get All Users' />

            {modalClicked ? <EditUserModal updateState={getUser} show={true.toString()} open user={modalClicked} handleClicked={handleModalClicked} /> : null}

            <Div className="col-md-12">
                <Div className="page-header-toolbar">

                    <Div className="btn-group toolbar-item" role="group" >
                        <Input type="text" name="email" id="email" onChange={handleSearchUser} />
                    </Div>


                </Div>
            </Div>
            {userList.length > 0 ?
                <Div className="col-md-12 margin_top">
                    <table className="table" border="1">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Company Name</th>
                                <th>Status</th>
                                <th>Time

                                </th>
                                <th>Action

                                </th>
                            </tr>
                        </thead>
                        <tbody id="tableBody">
                            {currentItems.map((user) =>

                                <tr key={user.user_id}>
                                    <td>{user.user_name}</td>
                                    <td>{user.user_email}</td>
                                    <td>{user.company_name}</td>
                                    <td>{user.status}</td>
                                    <td>{user.designation}</td>
                                    <td>
                                        {renderActionButtons(user)}
                                    </td>
                                </tr>
                            )

                            }
                        </tbody>
                    </table>
                    {userList.length > 0 ?
                    <Stack spacing={2}>
                        <Pagination count={totalPages} color="primary" page={currentPage} onChange={paginate}>

                        </Pagination>

                    </Stack>: null
               } 
                </Div> :
                <>
                    <p style={{marginTop:'30px', marginLeft:'15px'}}>No User Available</p>
                </>}
        </>
    )
}