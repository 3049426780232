import { createPortal } from "react-dom";
import Div from "./Div";
import Input from "./Input";
import FormButton from "./FormButton";
import { useEffect, useState } from "react";
import { getAdminDetails } from "../http/AdminVerifyHttp";
import Form from "./Form";
import { editProfile } from "../http/UserHttp";


export default function ProfileModal({ setModalClicked, ...props }) {

    const [adminDetails, setAdminDetails] = useState();
    const [inputName, setInputName] = useState();
    const [inputPhoneNumber, setPhoneNumber] = useState();

    useEffect(() => {
        const getAdmin = async () => {

            let adminResponse = await getAdminDetails();
            if (adminResponse){
            // adminResponse = await adminResponse.json()
            setAdminDetails(adminResponse.admins);
            setInputName(adminResponse.admins.admin_name)
            setPhoneNumber(adminResponse.admins.phone_number)
            }
            else{
                alert('Failed to load Admin Data')
            }

        }

        getAdmin()

    }, [])

    function handleChangeNameInput(event) {
        setInputName(event.target.value)
    }

    function handleChangePhoneInput(event) {
        setPhoneNumber(event.target.value)
    }


    async function handleEditProfile(event){
        event.preventDefault()
        const statusFD = new FormData(event.target)

        const data = Object.fromEntries(statusFD.entries())

        console.log(data)
        
        const editProfileResponse = await editProfile(data)
        console.log(editProfileResponse)
        if (!editProfileResponse.error){
           setModalClicked(false)
        }
        
    }

    return (
        <>
            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1' }}>

                        <Div className="form-group">
                            User Profile
                        </Div>
                        <Form onSubmit={handleEditProfile}>
                            <Div className="form-group">
                                <Input type="text" name='name' onChange={handleChangeNameInput} className="form-control" value={inputName} placeholder="Enter User Name" />
                            </Div>

                            <Div className="form-group">
                                <Input type="text" className="form-control" value={adminDetails ? adminDetails.admin_email : ''} placeholder="Enter User Email" disabled />
                            </Div>

                            <Div className="form-group">
                                <Input type="text" name='phone_number'className="form-control" onChange={handleChangePhoneInput} value={inputPhoneNumber} placeholder="Enter User Phone Number" />
                            </Div>


                            <Div className="form-group">
                                <Div className='row'>
                                    <Div className='col-sm-6'>
                                        <FormButton type='submit' className="btn btn-primary submit-btn btn-block">Edit Profile</FormButton>
                                    </Div>
                                    <Div className='col-sm-6'>
                                        <FormButton type='button' onClick={() => setModalClicked(false)} className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                                    </Div>
                                </Div>

                                <Div className='row'>
                                    <Div className='col-sm-12'>
                                        {/* <p style={{color:'red',marginLeft: '15px'}}> OTP Does Not Match</p>  */}
                                    </Div>
                                </Div>

                            </Div>
                        </Form>
                    </dialog>

                </Div>
                , document.getElementById('modal'))

            }
        </>
    )
}