import Div from "./Div"
import FormButton from "./FormButton"
import { createPortal } from "react-dom"
import { useState } from "react";
import { GoogleAddLicenseModal } from "./GoogleAddLicenseModal";

export function GoogleSubscriptionModal({ updateState, domain_name, googleLicense, handleClicked, ...props }) {


    const [addModalClicked, handleAddModalClicked] = useState(false)

    function handleClose() {
        console.log(googleLicense)
        handleClicked(false);
    }

    function handleGoogleAddLicense(subscription_id, customer_id, seats, planName) {

        const subscription = {
            'subscription_id': subscription_id,
            'customer_id': customer_id,
            'seats': seats,
            'plan_name': planName
        }
        handleAddModalClicked(subscription)
    }

    return (
        <>
            {createPortal(

                <Div className="modal-backdrop" style={{ scrollX: true }}>
                    <dialog {...props} style={{ zIndex: '1', width:'90%', overflowX:'scroll' }}>
                        <Div className="form-group">
                            {addModalClicked ? <GoogleAddLicenseModal googleLicense={addModalClicked}
                                show={true.toString()} domain_name={domain_name} open handleClicked={handleAddModalClicked} /> :
                                null}
                            Add License {domain_name}
                        </Div>
                        <Div >

                            {googleLicense.length > 0 ?
                                <table className="table" border="1">
                                    <thead >
                                        <tr>
                                            <th>SKU Name</th>
                                            <th>Plan Name</th>
                                            <th>Status</th>
                                            <th>Licensed Number Of Seats</th>
                                            <th>Maximum Number Of Seats</th>
                                            <th>Number Of Seats</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tableBody">

                                        {googleLicense.map((domain) =>
                                            domain.subscription.map((subscription) =>
                                                <>
                                                    <tr style={{marginTop:'20px'}} key={subscription.subscription_id}>
                                                        <td>{subscription.sku_name}</td>
                                                        <td>{subscription.plan_name}</td>
                                                        <td>{subscription.status}</td>
                                                        {subscription.plan_name !== 'FREE' ?
                                                            <>
                                                                <td>{subscription.licensed_seats}</td>
                                                                <td>{subscription.max_seats}</td>
                                                            </>
                                                            : <>
                                                                <td></td>
                                                                <td></td>
                                                            </>}
                                                        {subscription.plan_name !== 'FLEXIBLE' ?
                                                            <td>{subscription.number_of_seats}</td> :
                                                            <td></td>
                                                        }
                                                        <td>
                                                            <button className="btn btn-primary submit-btn btn-block" onClick={() =>
                                                                handleGoogleAddLicense(subscription.subscription_id, subscription.customer_id, subscription.number_of_seats, subscription.plan_name)}>
                                                                Add License</button>
                                                        </td>
                                                    </tr>

                                                </>
                                            )
                                        )}

                                    </tbody>

                                </table> : <p> No Subscription Found in this Domain </p>

                            }
                        </Div>
                        <Div className='col-sm-6'>
                            <FormButton type='button' style={{ margin: '15px', width: '25%' }} onClick={handleClose}
                                className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                        </Div>

                    </dialog >

                </Div >
                , document.getElementById('modal'))

            }
        </>
    )

}