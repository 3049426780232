import { API_URL } from "../config"
import { getToken } from "../util/validation"

const token = JSON.parse(getToken);

export async function adminLoginHttp(email, password, otp) {

    try {
        var data = {
            'email': email,
            'password': password,
            'otp': otp
        }

        const response = await fetch(API_URL + 'adminpanel/admin_login/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })

        return response;
    }
    catch (err) {
        return false

    }
}

export async function inviteAdmin(formData, menuList) {

    try {
        let data = {
            'formData': formData,
            'menuList': menuList,
            'role': 'ADMIN'
        }

        const response = await fetch(API_URL + 'adminpanel/invite_admin/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        })

        if (response.status === 200) {
            return true
        }
        return false
    } catch (error) {
        return false
    }

}

export async function resetAdminPassword(formData) {
    try {
        let data = {
            'formData': formData,
        }

        const response = await fetch(API_URL + 'adminpanel/reset_admin_password/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
        console.log(response)
        if (response.status === 200) {
            return true
        }
        return false
    } catch (error) {
        return false
    }

}

export async function getAllAdmin() {

    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_all_admins/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {

            return response.json()
        }
    } catch (error) {
        return false
    }
}

export async function updateAdminDetails(formData, menuItem) {

    try {
        const email = localStorage.getItem('user')
        var data = {
            'email': email,
            'fomrData': formData,
            'menuItem': menuItem
        }

        const response = await fetch(API_URL + 'adminpanel/update_admin_details/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {

            return response.json()
        }
        else {
            return false
        }
    } catch (error) {
        return false
    }
}

export async function getAdminDetails() {

    try {

        const email = localStorage.getItem('user')
        var data = {
            'email': email
        }

        const response = await fetch(API_URL + 'adminpanel/get_admin_details/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {

            return response.json()
        }else{
            return false
        }

    } catch (error) {
        return false
    }
}