import Input from '../components/Input';
import Header from '../components/Header';
import FormButton from '../components/FormButton';
import Div from '../components/Div';
import Footer from '../components/Footer';
import Form from '../components/Form';
import { Link } from 'react-router-dom';
import { loginHttp } from '../http/VerifyHttp';
import { useState } from 'react';
import Spinner from '../components/Spinner';
// import { useCookies, CookiesProvider } from 'react-cookie';

export default function Login() {

  const [loginError, setLoginError] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  // const [userCookie, setUserCookie] = useCookies(['user'])

  async function handleSubmit(event) {
    setIsLoading(true)
    event.preventDefault()
    const registerFD = new FormData(event.target)
    const data = Object.fromEntries(registerFD.entries())
    if (data.username === '' || data.password === '') {

      alert('Email or Password cannot be blank..!')
      setIsLoading(false)
      return
    }
    const loginResponse = await loginHttp(data.username, data.password)
    
    if (loginResponse.status === 200) {
      // setUserCookie('user', true, { path: '/', maxAge: 10000 })
      const loginJson = await loginResponse.json()

      localStorage.setItem('menu', JSON.stringify(loginJson.menu))
      localStorage.setItem('token', JSON.stringify(loginJson.token))
      localStorage.setItem('user',loginJson.email)
      console.log()
      // localStorage.setItem('expiration',)
      if (loginJson.menu[0]){
        window.location.href = '/home/' + String(loginJson.menu[0].to_route)
      }
      else{
        window.location.href = '/home'
      }

    }
    else if (loginResponse.status === 403) {
      // User In Active or Suspended
      setLoginError('User is Either Not Active or Suspended')
    }
    else if (loginResponse.status === 401) {
      // Wrong User Name Password
      setLoginError('Incorrect Username or Password..!')
    }
    else if (loginResponse.status === 404) {
      setLoginError('Email Not Found..!!')
    }
    else {
      // Something went wrong..!
      setLoginError('Something went Wrong...!')
    }
    setIsLoading(false)
  }
  return (
    <>
    {isLoading ? <Spinner/> : null }
      <Div className="container-scroller">
        <Div className="container-fluid page-body-wrapper full-page-wrapper">
          <Div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
            <Div className="row w-100">
              <Div className="col-lg-12 text-center mb-5">
                <Header title='Login' />
                <Div className="col-lg-4 mx-auto">
                  <Div className="auto-form-wrapper">
                    <Form action="#" onSubmit={handleSubmit}>
                      <Div className="form-group">
                        <Input text='Username' name='username' type="text" className="form-control" placeholder="Username" />
                      </Div>
                      <Div className="form-group">
                        <Input text='Password' name='password' type="password" className="form-control" placeholder="*********" />
                      </Div>
                      <Div className="form-group">
                        <FormButton className="btn btn-primary submit-btn btn-block">Login</FormButton>
                      </Div>
                      {loginError ? <Div className="form-group">
                        <span style={{ color: 'red' }}>{loginError}</span>
                      </Div> : null}
                      <Div className="form-group d-flex justify-content-between">
                        <Link to="forget_password" className="text-small forgot-password text-black">Forgot Password</Link>
                      </Div>
                      <Div className="text-block text-center my-3">
                        <span className="text-small font-weight-semibold">Not a member ?</span>
                        <Link to="register" className="text-black text-small">Create new account</Link>
                      </Div>
                    </Form>
                  </Div>
                  <Footer />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </>
  )
}