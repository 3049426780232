import Input from '../../components/Input';
import Header from '../../components/Header';
import FormButton from '../../components/FormButton';
import Div from '../../components/Div';
import Footer from '../../components/Footer';
import Form from '../../components/Form';
import { Link } from 'react-router-dom';
import { adminLoginHttp } from '../../http/AdminVerifyHttp';
import { useState } from 'react';
import { sendOtp } from '../../http/VerifyHttp';
import Spinner from '../../components/Spinner';


export default function AdminLogin() {


  //  Handle Login Error
  const [loginError, setLoginError] = useState(false);

  //Handle Button Click State
  const [OTPClicked, setOTPClicked] = useState(false);

  // Handle Loading State
  const [isLoading, setIsLoading] = useState(false);

  // Handle Email Input State
  const [inputEmail, handleInputEmail] = useState('')

  const handleEmailOnChange = (event) => {
    setLoginError(false)
    handleInputEmail(event.target.value)
  }

  async function handleSendOTP() {
    const otpResponse = await sendOtp(inputEmail)
    if(otpResponse){
          setOTPClicked(true);
    }
    else{
      setOTPClicked(false)
    }
  }

  async function handleSubmit(event) {

    setIsLoading(true)   
    event.preventDefault()
    const registerFD = new FormData(event.target)
    const data = Object.fromEntries(registerFD.entries())
    if (data.username === '' || data.password === '') {

      alert('Email or Password cannot be blank..!')
      setIsLoading(false)
      return false
    }

      const loginResponse = await adminLoginHttp(data.username, data.password, data.otp)

     
      if (loginResponse.status === 200) {

        const loginJson = await loginResponse.json()
      
        if (loginJson.firstLogin) {
          localStorage.setItem('user', data.username)
          window.location.href = '/adminpanel/reset_password'
        }
        else{
          localStorage.setItem('user',loginJson.email)
          localStorage.setItem('token',JSON.stringify(loginJson.token))
          localStorage.setItem('menu', JSON.stringify(loginJson.menu))
          window.location.href = '/home/'+ String(loginJson.menu[0].to_route)
        }
      
        // setUserCookie('admin_user', true, { path: '/', maxAge: 10000 })
        setIsLoading(false)
      }
      else if (loginResponse.status === 403) {
        // User In Active or Suspended
        setLoginError('Something went wrong...!')
        // setIsLoading(false)
      }
      else if (loginResponse.status === 401) {
        // Wrong User Name Password
        setLoginError('Incorrect Username or Password..!')
        // setIsLoading(false)
      }
      else if (loginResponse.status === 404) {
        setLoginError('Email Not Found..!!')
        // setIsLoading(false)
      }
      else {
        // Something went wrong..!
        setLoginError('Something went Wrong...!')
      }
      setIsLoading(false)
      return
  }

  return (
    <>
    {isLoading ? <Spinner/> : null}
      <Div className="container-scroller">
        <Div className="container-fluid page-body-wrapper full-page-wrapper">

          <Div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
            <Div className="row w-100">
              <Div className="col-lg-12 text-center mb-5">
                <Header title='Admin Login' />

                <Div className="col-lg-4 mx-auto">
                  <Div className="auto-form-wrapper">
                    <Form action="#" onSubmit={handleSubmit}>
                      <Div className="form-group">
                        <Input text='Username' name='username' onChange={handleEmailOnChange} type="email"
                          className="form-control" placeholder="Email" />
                      </Div>
                      <Div className="form-group">
                        <Input text='Password' name='password' type="password" className="form-control" placeholder="*********" />
                      </Div>
                      <Div  className="form-group">
                        <FormButton className="btn btn-primary submit-btn btn-block"
                          onClick={handleSendOTP}
                          type= 'button'
                        >
                          Send OTP
                        </FormButton>

                      </Div>
                      {OTPClicked ?
                        <Div className="form-group">
                          <Input text='otp' name='otp' type="text" className="form-control" placeholder="Enter OTP" />
                        </Div>
                        : null}
                      <Div className="form-group">

                        {OTPClicked ?
                          <FormButton className="btn btn-primary submit-btn btn-block">Login</FormButton> :
                          null
                        }

                      </Div>
                      {loginError ? <Div className="form-group">
                        <span style={{ color: 'red' }}>{loginError}</span>
                      </Div> : null}
                      <Div className="form-group d-flex justify-content-between">
                        <Link to="forget_password" className="text-small forgot-password text-black">Forgot Password</Link>
                      </Div>
                      {/* <Div className="text-block text-center my-3">
                        <span className="text-small font-weight-semibold">Not a member ?</span>
                        <Link to="register" className="text-black text-small">Create new account</Link>
                      </Div> */}
                    </Form>
                  </Div>
                  <Footer />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </>
  )
}