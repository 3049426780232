import Div from "../../components/Div"
import Form from "../../components/Form"
import PageTitle from "../../components/PageTitle"
import Input from "../../components/Input"
import FormButton from "../../components/FormButton"
import { useState } from "react"
import { addCustomer } from "../../http/CustomerHttp"
import Spinner from "../../components/Spinner"


export default function AddCustomer() {

    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    async function handleSubmit(event){
        setIsLoading(true)
        event.preventDefault()
        const registerFD = new FormData(event.target)
        const data = Object.fromEntries(registerFD.entries())
        console.log(data);
        
        const addCustomerResponse = await addCustomer(data)
        
        if(addCustomerResponse){
            window.location.reload();
        }
        else {
        // Something went wrong..!
        setError('Something went Wrong...!')
        }
        setIsLoading(false)

    }

    return (
        <>
            <PageTitle title='Add Customer' />

            {isLoading ? <Spinner/> : null }

            <Div className="col-md-12">
                <Div className="page-header-toolbar">

                    <Div className="btn-group toolbar-item" role="group" >
                        <Div className='m-lg-auto' style={{ inset: '0px', width: '50rem',height: '12rem',
                         maxHieght:'100dvh', maxWidth: '100vw', margin: 'auto'}}>
                            <Div className='card'>
                                <Form onSubmit={handleSubmit} style={{margin:'15px'}}>
                                    <Div className="form-group">
                                        <Input text='text' name='domain_name' type="text" className="form-control" placeholder="Domain Name" />
                                    </Div>
                                    <Div className="form-group">
                                        <Input text='text' name='first_name' type="text" className="form-control" placeholder="First Name" />
                                    </Div>
                                    <Div className="form-group">
                                        <Input text='text' name='last_name' type="text" className="form-control" placeholder="Last Name" />
                                    </Div>
                                    <Div className="form-group">
                                        <Input text='text' name='company_name' type="text" className="form-control" placeholder="Company Name" />
                                    </Div>
                                    <Div className="form-group">
                                        <Input text='text' name='phone_number' type="text" className="form-control" placeholder="Phone Number" />
                                    </Div>
                                    <Div className="form-group">
                                        <Input text='text' name='email' type="text" className="form-control" placeholder="Email" />
                                    </Div>
                                    <Div className="form-group">
                                        <FormButton className="btn btn-primary submit-btn btn-block">Login</FormButton>
                                    </Div>
                                    {error ? <Div className="form-group">
                                    <span style={{ color: 'red' }}>{error}</span>
                                </Div> : null}

                                </Form>
                            </Div>
                        </Div>


                    </Div>


                </Div>
            </Div>
        </>
    )
}