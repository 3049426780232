// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ApproveDomain from './pages/admin_pages/ApproveDomain';
import './assets/css/style.css'
import './assets/css/index_style.css'
import Register from './pages/Register';
import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';
import Error from './pages/Error';
import Home, { menuLoader } from './pages/Home';
import AdminLogin from './pages/admin_pages/AdminLogin';
// import MainComponent from './pages/SideComponent';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import DomainList from './pages/DomainList';
import AdminRegister, { departmentLoader } from './pages/admin_pages/AdminRegister';
// import AdminHome,{adminMenuLoader} from './pages/admin_pages/AdminHome'
import { validLoader, validLoginLoader, validateAdminLoginLoader, logoutLoader } from './util/validation';
import AddLicense from './pages/AddLicense';
import InviteAdmin from './pages/admin_pages/InviteAdmin';
import AdminResetPassword from './pages/admin_pages/AdminResetPassword';
import Logout from './pages/Logout';
import GetAllUser from './pages/admin_pages/GetAllUser';
import GetAllDomain from './pages/admin_pages/GetAllDomain';
import ApproveUsers from './pages/admin_pages/ApproveUsers';
import GetAllAdmin from './pages/admin_pages/GetAllAdmin';
import AddCustomer from './pages/admin_pages/AddCustomer';
import { testLoader } from './util/validation';
import UpdateCustomer from './pages/admin_pages/UpdateCustomer';
import ShivaamiPortal from './pages/admin_pages/ShivaamiPortal';
import AddZohoTask from './pages/admin_pages/AddZohoTask';
import GetRenewalData from './pages/admin_pages/GetRenewalData';
import GetMonthlyRenewal from './pages/admin_pages/GetMonthlyRenewal';
import RenewalDashboard from './pages/admin_pages/RenewalDashboard';
import ManageRenewalTeam from './pages/admin_pages/ManageRenewalTeam';
import MyRenewalDomain from './pages/admin_pages/MyRenewalDomain';

const route = createBrowserRouter([
  {
    path: '/', errorElement: <Error />, loader: testLoader, children: [
      {
        index: true, element: <Login />, loader: validLoginLoader
      },
      {
        path: 'register', element: <Register />
      },
      {
        path: 'forget_password', element: <ForgetPassword />,
      },
      {
        path: 'logout', element:<Logout/>, loader:logoutLoader
      },
      {
        path: 'home', element: <Home />, loader: menuLoader, children: [
          {
            path: 'map_domain', element: <DomainList />, loader: validLoader
          },
          {
            path: 'add_license', element: <AddLicense />, loader: validLoader
          },
          {
            path: 'invite_admin', element: <InviteAdmin />, loader: validLoader
          },
          {
            path:'get_all_users', element:<GetAllUser/>
          },
          {
            path:'approve_domain', element:<ApproveDomain/>
          },
          {
            path:'get_all_domain', element: <GetAllDomain/>
          },
          {
            path:'approve_users', element: <ApproveUsers/>
          },
          {
            path:'get_all_admin', element: <GetAllAdmin/>
          },
          {
            path:'add_customer', element: <AddCustomer/>
          },
          {
            path:'update_customer', element: <UpdateCustomer/>
          },
          {
            path:'shivaami_portal', element: <ShivaamiPortal/>
          },
          {
            path:'add_zoho_task', element: <AddZohoTask/>
          },
          {
            path:'get_renewal_data', element: <GetRenewalData/>
          },
          {
            path:'get_monthly_renewal', element: <GetMonthlyRenewal/>
          },
          {
            path:'renewal_dashboard', element: <RenewalDashboard/>
          },
          {
            path:'manager_renewal_team', element: <ManageRenewalTeam/>
          },
          {
            path:'my_renewal_domain', element: <MyRenewalDomain/>
          }
        ]
      },
     
    ]
  },



  {
    path: '/adminpanel', errorElement: <Error />, children: [
      {
        index: true, element: <AdminLogin />, loader: validateAdminLoginLoader
      },
      {
        path: 'register', element: <AdminRegister />, loader: departmentLoader
      },
      {
        path: 'forget_password', element: <ForgetPassword />,
      },
      {
        path: 'reset_password', element: <AdminResetPassword />
      }
    ]
  }
])


function App() {

  setInterval(
    testLoader,
    50000)
  return (

    <>
      <RouterProvider router={route} />
    </>

  );
}

export default App;
