import { createPortal } from "react-dom"
import Div from "./Div"
import Form from "./Form"
import FormButton from "./FormButton"
import { useEffect, useState } from "react"
import { getNotificationDataHttp } from "../http/RenewalHttp"
import Input from "./Input"
import { sendNotification } from "../http/RenewalHttp"

export default function SendNotificationModal({ notify, handleClicked, ...props }) {

    const [customer, setCustomer] = useState(false)

    async function getNotificationData(notify) {
        const customerResponse = await getNotificationDataHttp(notify)
        console.log(customerResponse.zoho_customer)
        setCustomer(customerResponse.zoho_customer)
    }
    useEffect(() => {
        getNotificationData(notify)
    }, [notify])

    function handleClose() {
        handleClicked(false)
    }

    function handleChangeInput(event, field) {
        setCustomer(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    }

    function handleSendNotification(event){
        event.preventDefault();

        const registerFD = new FormData(event.target)

        const data = Object.fromEntries(registerFD.entries())
        sendNotification(data, customer.domain_name, notify.subscription_id, notify.renewal_date)
        window.location.reload();
    }

    return (
        <>
            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1', maxWidth: '100%' }}>

                        <Div className="form-group">
                            Send Notification
                        </Div>

                        <Form onSubmit={handleSendNotification}>
                            {customer ? 
                                <>
                                    <Div className="form-group">
                                        {/* <label>Domain Name</label> */}
                                        <Input value={customer.domain_name} type="text" name='domain_name'
                                         className="form-control" disabled />
                                    </Div>
                    
                                    <Div className="form-group">
                                        <label>Customer Name</label>
                                        <Input value={customer.customer_name} name='customer_name'
                                        onChange={(event) => handleChangeInput(event, 'customer_name')}
                                         type="text" className="form-control" />
                                    </Div>

                                    <Div className="form-group">
                                        <label>Company Name</label>
                                        <Input value={customer.company_name} name='company_name'
                                        onChange={(event) => handleChangeInput(event, 'company_name')}
                                         type="text" className="form-control" />
                                    </Div>

                                    <Div className="form-group">
                                        <label>Email</label>
                                        <Input value={customer.email} type="text" name='email'
                                        onChange={(event) => handleChangeInput(event, 'email')}
                                         className="form-control" />

                                    </Div>

                                    <Div className="form-group">
                                        <label>Phone</label>
                                        <Input value={customer.phone} type="text" name='phone'
                                         onChange={(event) => handleChangeInput(event, 'phone')}
                                         className="form-control" />
                                    </Div>

                                </>
                            
                                : 'No Data Present'

                            }

                            <Div className="form-group">
                                <Div className='row'>
                                    <Div className='col-sm-6'>
                                        <FormButton type='submit' style={{ margin: '2px' }}
                                            className="btn btn-primary submit-btn btn-block" >Send Notification</FormButton>
                                    </Div>
                                    <Div className='col-sm-6'>
                                        <FormButton type='button' style={{ margin: '2px' }} onClick={handleClose}
                                            className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                                    </Div>
                                </Div>

                            </Div>

                        </Form>
                    </dialog>

                </Div>
                , document.getElementById('modal'))

            }

        </>
    )
}