// src/Spinner.js

import React from 'react';
import '../assets/css/Spinner.css';
import spinnerImage from '../assets/images/shivaami200logo.png'; // Adjust the path based on your project structure

const Spinner = () => {
  return (
    <div className="overlay">
    <div className="spinner">
      <img src={spinnerImage} alt="Loading..." />
    </div>
    </div>
  );
};

export default Spinner;
