import { API_URL } from "../config"

export function verifyOTP(otp, email) {
    var data = {
        'email': email,
        'otp': otp
    }

    return fetch(API_URL + 'verifyotp/', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then((success) => {
            if (success.status === 200) {
                return true
            }
            else {
                return false
            }

        })
        .catch((error) => {
            return false
        });
}

export async function sendOtp(email) {
    try {
        var data = {
            'email': email
        }
        const response = await fetch(API_URL + 'sendotp/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
        const responseJson = response.json()
        
        if (!response.ok) {
            return false
        }
        return responseJson
    }
    catch (error) {
        alert('Failed to send OTP')
        return false
    }

}

export async function forgetPassword(email, otp) {

    var data = {
        'email': email,
        'otp': otp
    }

    const response = await fetch(API_URL + 'verifyotp/', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })

    if (!response.ok) {
        return false
    }
    return response.otp
}

export async function registerHttp(formData) {
    try {

        var data = {
            'formData': formData
        }

        const response = await fetch(API_URL + 'signup/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        return response;
    }
    catch(error){
        alert('Something went wrong. Please try again after some time..!');
        return false
    }

}

export async function loginHttp(email, password) {

    try {
        var data = {
            'email': email,
            'password': password
        }

        const response = await fetch(API_URL + 'login/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })

        return response
    }
    catch (error) {
        return false
    }

}