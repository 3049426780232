import Div from "../../components/Div"
import PageTitle from "../../components/PageTitle"
import { useState, useEffect } from "react";
import { getPendingDomains, setPendingDomainStatus } from "../../http/DomainHttp";
import { Button } from "react-bootstrap";
// import Spinner from "../../components/Spinner";

export default function ApproveDomain() {

    const [domainList, setDomainList] = useState([]);
    // const [googleDomain, setGoogleDomain] = useState([]);

    const getDomains = async () => {
        const response = await getPendingDomains()
        if (response){
            setDomainList(response.domains)
        }
        else{
            return
        }
    }
    useEffect(() => {

        getDomains()

    }, [])

    async function setPendingDomain(domain_id, domain_name, status) {

        const setPendingDomainResponse = await setPendingDomainStatus(domain_id, status, domain_name)
        if (setPendingDomainResponse){
            window.location.reload();
        }
        else{
            alert('Failed to Approve Domain...!')
        }

    }

    return (
        <>

        {/* <Spinner/> */}
            <PageTitle title='Approve Domain' />

            <Div className="col-md-12">
                {domainList.length > 0 ? <table className="table" border="1">
                    <thead>
                        <tr>
                            <th>Domain Name</th>
                            <th>Email</th>
                            <th>Company Name</th>
                            <th>Action</th>

                        </tr>
                    </thead>
                    <tbody id="tableBody">
                        {domainList.map((domain) =>

                            <tr key={domain.domain_id}>
                                <td>{domain.domain_name}</td>
                                <td>{domain.user_email}</td>
                                <td>{domain.company_name}</td>
                                <td>
                                    {/* { renderActionButtons(domain.domain_id)} */}
                                    <Button variant="success" onClick={() => setPendingDomain(domain.domain_id, domain.domain_name, 'ACTIVE')}> ✔ </Button>
                                    <Button variant="danger" onClick={() => setPendingDomain(domain.domain_id, domain.domain_name, 'REJECTED')}
                                        style={{ marginLeft: '15px' }}> ✘ </Button>
                                </td>
                            </tr>
                        )

                        }
                    </tbody>
                </table> :
                    <>
                        <p>Not Domain Pending for Approval</p>
                    </>}
            </Div>

        </>
    )
}