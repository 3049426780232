import Input from "./Input.js";
import Div from "./Div.js";
import FormButton from "./FormButton.js";
import {verifyOTP} from '../http/VerifyHttp.js'
import { useState } from "react";
import {createPortal} from 'react-dom';
import {sendOtp} from '../http/VerifyHttp.js'

function OTPModal({ handleOTPClicked, email, setOTPverified, ...props }) {

    const [verify, handleVerifyClicked] = useState(false);
    const [otp, handleInputOtp] = useState('');
    const [error,setOtpError] = useState(false)

    function handleClose() {
        handleOTPClicked(false);
    }

    function handleVerify() {
        verifyOTP(otp, email)
        .then( (valid) =>{
            if(!valid){        
                handleOTPClicked(true);
                setOtpError(true);
                handleVerifyClicked(true);
            }
            else{
                handleOTPClicked(false);
                setOTPverified(true)
            }
        })
        .catch((error) =>{
            console.log(error)
        });
    }

    function handleResendOTP(){
        sendOtp(email)
    }

    return (

        <>
        { createPortal(
            <Div className="modal-backdrop">
                <dialog {...props} style={{ zIndex: '1' }}>

                    <Div className="form-group">
                        <Input text='' onChange={(event) => handleInputOtp(event.target.value)} type="text" className="form-control" placeholder="Enter OTP" />
                    </Div>
                    <Div className="form-group">
                        <Div className='row'>
                            <Div className='col-sm-6'>
                                <FormButton type='button' className="btn btn-primary submit-btn btn-block" onClick={handleVerify}>Verify</FormButton>
                            </Div>
                            <Div className='col-sm-6'>
                                <FormButton type='button' className="btn btn-primary submit-btn btn-block" onClick={handleClose}>Close</FormButton>
                            </Div>
                        </Div>
                        <br>
                        </br>
                        <Div className='row'>
                            <Div className='col-sm-12'>
                                {verify ? <FormButton type='button' onClick={handleResendOTP} className="btn btn-link">Resend OTP</FormButton> : null}
                            </Div>
                        </Div>
                        <Div className='row'>
                            <Div className='col-sm-12'>
                                { error ? <p style={{color:'red',marginLeft: '15px'}}> OTP Does Not Match</p> : null}
                            </Div>
                        </Div>
                    </Div>


                </dialog>
            </Div>,document.getElementById('modal'))}
        </>
    )
}

export default OTPModal;