import Div from "../../components/Div"
import PageTitle from "../../components/PageTitle"
import { useState, useEffect } from "react";
import { getPendingUsers } from "../../http/UserHttp";
import { Button } from "react-bootstrap";
import UserDelegationModal from '../../components/UserDelegationModal'

export default function ApproveUsers() {

    const [userList, setUserList] = useState([]);
    const [modalClicked, setModalClicked] = useState(false);
    const [userStatus, setUserStatus] = useState();

    const getUsers = async () => {
        const response = await getPendingUsers()
        if (response){
            setUserList(response.users)
        }
 
    }
    useEffect(() => {

        getUsers()

    }, []);

    async function setPendingUser(user_id, status) {

        setUserStatus({
            'user_id': user_id,
            'status': status
        })
        // await setPendingUserStatus(user_id, status)
        // await getUsers()
        setModalClicked(true)
    }

    return (
        <>
            <PageTitle title='Approve Users' />
            {modalClicked ? <UserDelegationModal show={true.toString()} buttonText='Approve' setHttpSubmit={userStatus} getUsers={getUsers} open handleClicked={setModalClicked} /> : null}
            <Div className="col-md-12">

                {userList.length > 0 ? <table className="table" border="1">
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Company Name</th>
                            <th>Action</th>

                        </tr>
                    </thead>
                    <tbody id="tableBody">
                        {userList.map((user) =>

                            <tr key={user.user_id}>
                                <td>{user.user_name}</td>
                                <td>{user.user_email}</td>
                                <td>{user.company_name}</td>
                                <td>
                                    {/* { renderActionButtons(user.user_id)} */}
                                    <Button variant="success" onClick={() => setPendingUser(user.user_id, 'ACTIVE')}> ✔ </Button>
                                    <Button variant="danger" onClick={() => setPendingUser(user.user_id, 'REJECTED')}
                                        style={{ marginLeft: '15px' }}> ✘ </Button>
                                </td>
                            </tr>
                        )

                        }
                    </tbody>
                </table> :
                    <>
                        <p>No Pending User for Approval</p>
                    </>}
            </Div>

        </>
    )
}