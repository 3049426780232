export const status = {

    'status': [{
        'id': 'ACTIVE',
        'value': 'Active'
    },
    {
        'id': 'SUSPENDED',
        'value': 'Suspend'
    },
    {
        'id': 'REJECTED',
        'value': 'Reject'
    }]
}

export const industryType = {
    'type': [{
        'id': 'SMB',
        'value': 'SMB'
    },
    {
        'id': 'CORP',
        'value': 'Corporate.'

    }]
}

export const segments = {
    'segment': [
        {
            'id': 'Micro',
            'value': 'Micro'
        },
        {
            'id': 'SMB',
            'value': 'SMB'
        },
        {
            'id': 'Corp',
            'value': 'Corp'
        },
        {
            'id': 'Enterprise',
            'value': 'Enterprise'
        },
        {
            'id': 'Edu-Tech',
            'value': 'Edu-Tech'
        },
        {
            'id': 'Startup',
            'value': 'Startup'
        },
    ]
}

export const zones = {
    'zone': [
        {
            'id': 'North',
            'value': 'North'
        },
        {
            'id': 'West',
            'value': 'West'
        },
        {
            'id': 'South',
            'value': 'South'
        },
    ]
}

export const highlight = {
    'comment': [
        {
            'id': 'Green',
            'value': 'Green'
        }, {
            'id': 'Orange',
            'value': 'Orange'
        }, {
            'id': 'Red',
            'value': 'Red'
        }
    ]
}

export const comment_status = {
    'status': [
        {
            'id': 'Confirmation Received',
            'value': 'Confirmation Received'
        },

        {
            'id': 'In Discussion',
            'value': 'In Discussion'
        },

        {
            'id': 'Partial Upgrade',
            'value': 'Partial Upgrade'
        },

        {
            'id': 'Lost',
            'value': 'Lost'
        },

        {
            'id': 'Upgrade',
            'value': 'Upgrade'
        },

        {
            'id': 'Move To Axima',
            'value': 'Move to axima'
        },

        {
            'id': 'Suspended',
            'value': 'Suspended'
        },

        {
            'id': 'Confirmation received for flex',
            'value': 'Confirmation received for flex'
        },

        {
            'id': 'Mid Term Upgrade',
            'value': 'Mid Term Upgrade'
        },

        {
            'id': 'Extension',
            'value': 'Extension'
        },
        {
            'id': 'Moved to O365',
            'value': 'Move to 0365 with other Vendor'

        }
    ]
}

export const aggrement_status = {
    'status': [
        {
            'id': 'Sent',
            'value': 'Sent'
        },
        {
            'id': 'Signed',
            'value': 'Signed'
        },
        {
            'id': 'Pending',
            'value': 'Pending'
        }
    ]
}

export const yesno = {
    'options': [{
        'id': 'Yes',
        'value': 'Yes'
    },
    {
        'id': 'No',
        'value': 'No'
    }
    ]
}

export const reason = {
    'reasons': [{
        'id': 'Move to other vendor',
        'value': 'Move to other vendor'
    },
    {
        'id': 'Move to other service',
        'value': 'Move to other service'
    }
    ]
}

export const sub_reason = {
    'sub_reasons': [{
        'id': 'Price Issue',
        'value': 'Price Issue'
    },
    {
        'id': 'Support Issue',
        'value': 'Support Issue'
    }
    ]
}