import { useState, useEffect } from "react";
import { getAllDomains, getGoogleDomainDetails } from '../../http/DomainHttp'
import PageTitle from '../../components/PageTitle'
import Div from "../../components/Div";
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Dropdown } from 'react-bootstrap'
import EditDomainModal from "../../components/EditDomainModal";
import Input from "../../components/Input";
import { BsFilter } from "react-icons/bs";
import { Pagination, Stack } from "@mui/material";
import { GoogleSubscriptionModal } from "../../components/GoogleSubscriptionModal";

export default function GetAllDomain() {

    const [domainList, setDomainList] = useState([]);
    // const [isAscending, setIsAscending] = useState(true);
    const [modalClicked, handleModalClicked] = useState(false)
    const [initailDomainList, setInitialDomainList] = useState([])
    // const [googleDomain, setGoogleDomain] = useState([]);

    const [subscriptionList, setSubscriptionList] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = domainList.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(domainList.length / itemsPerPage);

    // Change page
    const paginate = (event, value) => {
        setCurrentPage(value);
      };

    const getDomains = async () => {
        const response = await getAllDomains()
        setDomainList(response.domains)
        setInitialDomainList(response.domains)
    }
    useEffect(() => {

        getDomains()

    }, []);

    function handleSearchDomain(event) {

        const search = event.target.value;
        const filteredList = initailDomainList.filter((domain) => {
            
            if (domain && domain.domain_name) {
                
                return domain.domain_name.toLowerCase().includes(search.toLowerCase());
            } 
            else{
                return false
            }
        });
         
        setDomainList(filteredList);

    }

    const renderActionButtons = (domain_name, status, domain_id) => {
        return (
            <div className="d-flex justify-content-center">
                <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                        <BsThreeDotsVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => editDomainModal(domain_name, status, domain_id)} >
                            Edit
                        </Dropdown.Item>
                    </Dropdown.Menu>

                </Dropdown>
            </div>
        );
    };

    function editDomainModal(domain_name, status, domain_id) {
        handleModalClicked({ 'domain_name': domain_name, 'status': status, 'domain_id': domain_id })
    }

    function handleStatusFilter(filterState) {

        const filteredList = initailDomainList.filter((user) => {
            if (user && user.domain_status) {

                return user.domain_status.toLowerCase().includes(filterState.toLowerCase());
            } else {
                return false;
            }
        });
        
        setDomainList(filteredList);
    }

    async function handleDomainClick(domain_id){
        const googleSubscriptionResponse = await getGoogleDomainDetails(domain_id)
        if (googleSubscriptionResponse){
            console.log(googleSubscriptionResponse.subscription)
            setSubscriptionList(googleSubscriptionResponse.subscription)
        }
        else{
            alert('No Subscription for this Domain')
        }
        console.log(googleSubscriptionResponse)

    }

    // const sortData = () => {
    //     const sorted = [...initailDomainList].sort((a, b) => {
    //       const dateA = new Date(a.timestamp);
    //       const dateB = new Date(b.timestamp);
    //       return isAscending ? dateA - dateB : dateB - dateA;
    //     });
    //     setDomainList(sorted)
    //     // setSortedData(sorted);
    //     setIsAscending(!isAscending);
    //   };
    
    return (
        <>
            {subscriptionList ? <GoogleSubscriptionModal show={true.toString()} domain_name={modalClicked} googleLicense={subscriptionList} open handleClicked={setSubscriptionList} /> :null}
            {modalClicked ? <EditDomainModal updateState={getDomains} show={true.toString()} open domain_name={modalClicked} handleClicked={handleModalClicked} /> : null}
            <PageTitle title='All Domain' />
            <Div className="col-md-12">
                <Div className="page-header-toolbar">

                    <Div className="btn-group toolbar-item" role="group" >
                        <Input type="text" name="email" id="email" onChange={handleSearchDomain} />
                    </Div>

                    <Div className="filter-wrapper">
                    </Div>
                    <span>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                <BsFilter />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Active')}
                                >
                                    Active
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Rejected')}
                                >
                                    Rejected
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Suspended')}
                                >
                                    Suspended
                                </Dropdown.Item>

                            </Dropdown.Menu>

                        </Dropdown>

                    </span>
                </Div>
            </Div>

            <Div className="col-md-12 margin_top">
                {currentItems ? <table className="table" border="1">
                    <thead>
                        <tr>
                            <th>Domain Name</th>
                            <th>Email</th>
                            <th>Company Name</th>
                            <th>Status</th>
                            <th>Time
                            {/* <button onClick={sortData} variant="primary">
                                    <span className="ml-2">
                                        {isAscending ? '↓' : '↑'}
                                    </span>
                                </button> */}
                            </th>
                            <th>Action
                               
                            </th>
                        </tr>
                    </thead>
                    <tbody id="tableBody">
                        {currentItems.map((domain) =>

                            <tr key={domain.domain_id}>
                                <td><button onClick={()=>handleDomainClick(domain.domain_id)} className="btn btn-link ">{domain.domain_name}</button></td>
                                <td>{domain.user_email}</td>
                                <td>{domain.company_name}</td>
                                <td>{domain.domain_status}</td>
                                <td>{domain.timestamp}</td>
                                <td>
                                    {renderActionButtons(domain.domain_name, domain.domain_status, domain.domain_id)}
                                </td>
                            </tr>
                        )

                        }
                    </tbody>
                </table> :
                    <>
                        <p style={{marginTop:'30px'}}>Not Domain Present or Could not fetch Domains</p>
                    </>}

                    {domainList.length > 0 ?
                    <Stack spacing={2}>
                        <Pagination count={totalPages} color="primary" page={currentPage} onChange={paginate}>

                        </Pagination>

                    </Stack>: null
                }
            </Div>

        </>
    )
}