import Div from "./Div"

export default function PageTitle({title}) {
    return (
        <>
            <Div className="row page-title-header">
                <Div className="col-12">
                    <Div className="page-header">

                        <Div className="quick-link-wrapper w-100 d-md-flex flex-md-wrap">
                            <ul className="quick-links">
                                <h4 className="page-title">{title}</h4>
                            </ul>

                        </Div>
                    </Div>
                </Div>
            </Div>
        </>
    )
}