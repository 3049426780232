import Div from "../../components/Div"
import PageTitle from "../../components/PageTitle"
import FormButton from "../../components/FormButton"
import Input from "../../components/Input"
import { Stack, Pagination } from "@mui/material"
import { useState, useEffect } from "react"
import { getMyDomain } from "../../http/RenewalMemberHttp"
import { zones, segments } from "../../util/enum"
import Select from "../../components/Select"


export default function MyRenewalDomain() {


    const [customerList, setCustomerList] = useState([]);
    // const [isAscending, setIsAscending] = useState(true);
    const [initailCustomerList, setInitialCustomerList] = useState([])

    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = customerList.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(customerList.length / itemsPerPage);

    // Change page
    const paginate = (event, value) => {
        setCurrentPage(value);
    };

    const zone = zones.zone.map((zone) =>
        zone.id !== currentItems ?
            <option key={zone.id}
                name={zone.id}
                value={zone.id}>
                {zone.value}</option>
            : null);

    const state = segments.segment.map((state) =>
        state.id !== currentItems ?
            <option key={state.id}
                name={state.id}
                value={state.id}>
                {state.value}</option>
            : null)


    const getMyDomains = async () => {
        const response = await getMyDomain()
        if (response) {
            console.log(response)
            setCustomerList(response.domains)
            setInitialCustomerList(response.domains)
        } else {
            // window.location.href = '/error'
            // alert('Could not fetch data. Please contact your Administrator')
        }
    }

    useEffect(() => {
        getMyDomains()
    }, [])

    function handleSearchCustomer(event) {

        const search_item = event.target.value;

        console.log(search_item)
        const filteredList = initailCustomerList.filter((customer) => {

            if ((customer && customer.customerDomain)) {

                return customer.customerDomain.toLowerCase().includes(search_item.toLowerCase());
            }
            else {
                return false
            }
        });
        setCustomerList(filteredList);
        setSearch(search_item)
    }

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    return <>

        <PageTitle title='Manage Renewal Team' />

        <Div className="col-md-12">
            <Div className="page-header-toolbar">

                <Div className="btn-group toolbar-item" role="group" >

                    <Input type="text" name="email" id="email" placeholder='Enter Team Name'
                        value={search}
                        onChange={handleSearchCustomer}
                    />

                </Div>

            </Div>
        </Div>

        <Div className="col-md-12 margin_top">
            {currentItems.length > 0 ? <table className="table" border="1">
                <thead>
                    <tr>
                        <th>Customer Domain</th>
                        <th>Plan</th>
                        <th>Status</th>
                        <th>License</th>
                        <th>Users</th>
                        <th>Sku Id</th>
                        <th className="sortable-header"
                        // onClick={() => handleSort("renewal_date")}
                        >Renewal Date
                            {/* {sortColumn === "renewal_date" ? (
                                    sortOrder === "asc" ? (
                                        <FaSortUp className="sort-icon" />
                                    ) : (
                                        <FaSortDown className="sort-icon" />
                                    )
                                ) : (
                                    <FaSort className="sort-icon" /> // Default sort icon
                                )} */}
                        </th>
                        <th>Zone</th>
                        <th>Google Renewal Date</th>
                        <th>Price</th>
                        <th>Segments</th>
                        <th>Add Comment</th>
                        <th>Follow up History</th>
                        <th>Follow Up Comments</th>
                        <th>Highlighted Case Flag</th>
                        <th>Confirmation Status</th>
                        <th>Aggrement Status</th>
                        <th>Price Per User</th>
                        <th>Special Comment</th>
                        <th>Task Status</th>
                        <th>Renewed License</th>
                        <th>Extension Date</th>

                        <th></th>
                    </tr>
                </thead>
                <tbody id="tableBody">
                    {currentItems.map((customer) =>

                        <tr key={customer.renewal_id}>
                            <td>
                                {customer.customerDomain}
                            </td>
                            <td>{customer.plan}</td>
                            <td>{customer.status}</td>
                            <td>{customer.maxSeats}</td>
                            <td>{customer.usedSeats}</td>
                            <td>{customer.skuName}</td>
                            <td>{formatDate(customer.shivaami_renewal_date)}</td>
                            <td>
                                <Select name='zone' options={zone} defaultValue={customer.zone}
                                    // onChange={(e) => handleUpdateSelect(customer.renewal_id, 'zone', e.target.value)}

                                    style={{ width: 'auto' }} />
                                {/* {customer.zone} */}
                            </td>
                            <td>{formatDate(customer.Google_renewal_date)}</td>
                            <td>{customer.price}</td>
                            <td>
                                <Select name='user_status' options={state} defaultValue={customer.segments}
                                    // onChange={(e) => handleUpdateSelect(customer.renewal_id, 'segments', e.target.value)}

                                    style={{ width: 'auto' }} />
                                {/* {customer.segments} */}
                            </td>
                            <td>
                                <FormButton
                                    type='button'
                                    className="btn btn-primary submit-btn btn-block"
                                // onClick={() => handleAddComment(customer.renewal_id)}
                                >Add Comments</FormButton>
                            </td>

                            <td>
                                <FormButton
                                    type='button'
                                    className="btn btn-primary submit-btn btn-block"
                                // onClick={() => handleFollowUp(customer.renewal_id)}
                                >Follow up History</FormButton>
                            </td>
                            <td>{customer.followup_comments}</td>
                            <td></td>
                            <td>{customer.comments}</td>
                            <td>
                                {customer.agreement_status}
                            </td>
                            <td>{customer.price_per_user}</td>
                            <td>{customer.special_comments}</td>
                            <td>{customer.comment3 ? customer.comment3 : customer.comment2}</td>
                            <td>{customer.add_lic}</td>
                            <td>{customer.extension_date}</td>

                            <td>
                                <FormButton
                                    type='button'
                                    className="btn btn-link"
                                // onClick={() => handleSendNotification(customer.customerDomain, customer.renewal_id, customer.subscription_id, customer.renewal_date)}
                                >Send Notification</FormButton>
                            </td>
                        </tr>
                    )

                    }
                </tbody>
            </table> :
                <>
                    <p style={{ marginTop: '30px' }}>No Team Present or Could not fetch Team</p>
                </>}

            {currentItems.length > 0 ?
                <Stack spacing={2}>
                    <Pagination count={totalPages} color="primary" page={currentPage} onChange={paginate}>

                    </Pagination>

                </Stack> : null
            }
        </Div>

    </>
}