import Div from "./Div";
import Input from "./Input";
import FormButton from "./FormButton";
import { createPortal } from "react-dom";
import Form from "./Form";
import { useState } from "react";
import Select from "./Select";
import { status } from '../util/enum';
import { updateUserData } from "../http/UserHttp";
import UserEditDelegationModal from "./UserEditDelegationModal";

export default function EditUserModal({ updateState, user, handleClicked, ...props }) {

    let [updatedUser, setUpdatedUser] = useState(user)
    const [delegateModal, setDelegateModal] = useState(false)

    const state = status.status.map((state) =>
        state.id !== user.status ?
            <option key={state.id}
                name={state.id}
                value={state.id}>
                {state.value}</option>
            : null)

    function handleClose() {
        handleClicked(false);
    }

    async function handleUpdateStatus(event) {
        event.preventDefault();
        const statusFD = new FormData(event.target)

        const data = Object.fromEntries(statusFD.entries())

        if (data.user_status === ""){
            data.user_status = 'ACTIVE'
        }

        const response = await updateUserData(data)
        if (response){
            handleClicked(false)
            updateState()
        }

    }

    function handleChangeInput(event, field) {
        setUpdatedUser(user[field] = event.target.value)
    }

    return (
        <>
        {delegateModal ? <UserEditDelegationModal show={true.toString()} buttonText='Approve' open user={user} handleClicked={setDelegateModal}/> :null}
            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1', width: '40%' }}>

                        <Div className="form-group">
                            Edit User
                        </Div>
                        <Form onSubmit={handleUpdateStatus}>
                            <Div className="form-group">
                            <input text=''
                                    type="text" name='user_id' onChange={(event)=> handleChangeInput(event, 'user_id')} value={user.user_id || updatedUser.user_id} hidden />

                                <Input text=''
                                    type="text" onChange={(event) => handleChangeInput(event, 'user_name')} name='user_name' className="form-control" placeholder="Enter Usename Name" value={user.user_name || updatedUser.user_name} />
                            </Div>

                            <Div className="form-group">
                                <Input text=''
                                    type="text" name='user_email' onChange={(event) => handleChangeInput(event, 'user_email')} className="form-control" placeholder="Enter Emails" value={updatedUser.user_email} disabled />
                            </Div>

                            <Div className="form-group">
                                {/* <Input text=''
                                    type="text" name='user_status' className="form-control" placeholder="Enter Status" value={user.status}  /> */}
                                <Select name='user_status' options={state} defaultValue={user.status} />
                            </Div>

                            <Div className="form-group">
                                <Input text=''
                                    type="text" name='user_company_name' onChange={(event) => handleChangeInput(event, 'company_name')} className="form-control" placeholder="Enter Company Name" value={updatedUser.company_name} />
                            </Div>

                            <Div className="form-group">
                                <Input text=''
                                    type="text" name='user_designation' onChange={(event) => handleChangeInput(event, 'designation')} className="form-control" placeholder="Enter Designation" value={updatedUser.designation} />
                            </Div>

                            <Div className="form-group">
                                <Input text=''
                                    type="text" name='user_phoneNumber' onChange={(event) => handleChangeInput(event, 'designation')} className="form-control" placeholder="Enter Phone Number" value={updatedUser.user_phoneNumber} />
                            </Div>

                            <Div className="form-group">
                                <Input text=''
                                    type="text" name='other_contact_name' onChange={(event) => handleChangeInput(event, 'other_contact_name')} className="form-control" placeholder="Enter Other Contact Name" value={updatedUser.other_contact_name} />
                            </Div>

                            <Div className="form-group">
                                <Input text=''
                                    type="text" name='other_contact_number' onChange={(event) => handleChangeInput(event, 'other_contact_number')} className="form-control" placeholder="Enter Other Contact Number" value={updatedUser.other_contact_number} />
                            </Div>

                            <Div className="form-group">
                                {/* <Select name='domain_status' options={state} defaultValue={user.status} /> */}

                            </Div>

                            <Div className="form-group">
                                <Div className='row'>
                                    <Div className='col-sm-4'>
                                        <FormButton type='submit' className="btn btn-primary submit-btn btn-block">Edit User</FormButton>
                                    </Div>
                                    <Div className='col-sm-4'>
                                        <FormButton type='button' onClick={()=> setDelegateModal(true)} className="btn btn-primary submit-btn btn-block">Edit Delegation</FormButton>
                                    </Div>
                                    <Div className='col-sm-4'>
                                        <FormButton type='button' onClick={handleClose} className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                                    </Div>
                                </Div>

                                <Div className='row'>
                                    <Div className='col-sm-12'>
                                        {/* <p style={{color:'red',marginLeft: '15px'}}> OTP Does Not Match</p>  */}
                                    </Div>
                                </Div>
                            </Div>
                        </Form>
                    </dialog>

                </Div>
                , document.getElementById('modal'))

            }
        </>
    )

}