import Input from '../components/Input';
import Header from '../components/Header';
import FormButton from '../components/FormButton';
import Div from '../components/Div';
import Form from '../components/Form';
import OTPModal from '../components/OTPModal.js';
import { useState } from 'react'
import { sendOtp, registerHttp } from '../http/VerifyHttp.js'
import { checkPasswordMatch, checkPhoneNumber } from '../util/validation.js'
import { Link } from 'react-router-dom';
import Select from '../components/Select.js';
import { industryType } from '../util/enum.js';
import Spinner from '../components/Spinner.js';

export default function Register() {

    //Handle Button Click State
    const [OTPClicked, handleOTPClicked] = useState(false)

    //Set OTP verified
    const [OTPVerify, setOTPverified] = useState(false)

    // Handle Submit Button State
    const [verifyRegister, handleVerifyRegister] = useState(false)

    // Handle Input State
    const [inputEmail, handleInputEmail] = useState('')

    //Handle Submit State
    const [errorSubmit, handleErrorSubmit] = useState(false)

    //Loading State
    const [isLoading, setIsLoading] = useState(false)

    //Select List
    const industryTypeSelect = industryType.type.map((industry) =>
        <option key={industry.id}
            name={industry.id}
            value={industry.id}>
            {industry.value}</option>
    )

    // Hangdle Error Message State
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('')
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('')
    const [emailErrorMessage, setemailErrorMessage] = useState('')

    function handleOTP() {
        if (inputEmail) {
            setemailErrorMessage('')
            sendOtp(inputEmail)
            handleOTPClicked(true);
            handleErrorSubmit(false)
        }
        else {
            setemailErrorMessage("Email Id cannot be blank")
        }

    }

    const handleEmailOnChange = (event) => {
        handleErrorSubmit(false)
        setOTPverified(false)
        handleInputEmail(event.target.value)
    }


    function handlePhoneNumberLenght(event) {
        if (!checkPhoneNumber(event.target.value)) {
            setPhoneNumberErrorMessage('Invalid Phone Number')
            return
        }

        setPhoneNumberErrorMessage('')

    }

    async function handleSubmit(event) {

        event.preventDefault();
        setIsLoading(true)
        const registerFD = new FormData(event.target)

        const data = Object.fromEntries(registerFD.entries())

        if (!checkPasswordMatch(data.password, data.confirmPassword)) {
            setConfirmPasswordErrorMessage('Password Does not Match !')
            return
        }
        setConfirmPasswordErrorMessage('')

        if (!checkPhoneNumber(data.phoneNumber.length)) {
            setPhoneNumberErrorMessage('Invalid Phone Number')
        }
        handleErrorSubmit(false)
        setPhoneNumberErrorMessage('')

        const registerResponse = await registerHttp(data)

        if (registerResponse.status === 200) {
            window.location.href = '/'
        }
        else if (registerResponse.status === 403) {
            handleErrorSubmit('Email Already Exists')
            setOTPverified(false)

        }
        else {
            handleErrorSubmit('Something went Wrong..!!')
        }
        setIsLoading(false)
    }

    return (
        <>
         {isLoading ? <Spinner/> : null }
            <Div className="container-scroller">
                <Div className="container-fluid page-body-wrapper full-page-wrapper">
                    <Div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">

                        <Div className="row w-100">

                            <Div className="col-lg-12 text-center mb-5">
                                <Header title='Register' />
                                <Div className="col-lg-4 mx-auto">
                                    <Div className="auto-form-wrapper">
                                        <Form onSubmit={handleSubmit}>
                                            <Div className="form-group">
                                                <Input name='userName' text='' type="text" className="form-control" required placeholder="Username" />
                                            </Div>
                                            <Div className="form-group">
                                                <Input name='companyName' text='' type="text" className="form-control" required placeholder="Company Name" />
                                            </Div>
                                            <Div className="form-group">
                                                <Input onBlur={handlePhoneNumberLenght} name='phoneNumber' error={phoneNumberErrorMessage}
                                                    text='' type="text" className="form-control" required placeholder="Phone Number" />
                                            </Div>
                                            {OTPClicked ? <OTPModal show={true.toString()} open handleOTPClicked={handleOTPClicked} setOTPverified={setOTPverified}
                                                handleVerifyRegister={handleVerifyRegister} email={inputEmail} />
                                                : null}
                                            <Div className="form-group">
                                                <Div className="row">
                                                    <Div className="col-8">
                                                        <Input name='email' type="email" error={emailErrorMessage}
                                                            onChange={handleEmailOnChange} className="form-control" required placeholder="Email ID" />
                                                    </Div>
                                                    <Div className="col-4">
                                                        <FormButton type='button' className="btn btn-primary submit-btn btn-block"
                                                            onClick={handleOTP}> Send OTP</FormButton>
                                                    </Div>
                                                </Div>
                                            </Div>
                                            <Div className="form-group">
                                                <Input name='password' type="password" className="form-control" required placeholder="Password" />
                                            </Div>
                                            <Div className="form-group">
                                                <Input name='confirmPassword' error={confirmPasswordErrorMessage} type="password" className="form-control" required placeholder="Confirm Password" />
                                            </Div>
                                            <Div className="form-group">

                                                <Div className='row'>
                                                    <Div className='col-12'>
                                                        <Input name='designation' type="text" className="form-control" required placeholder="Designation" />
                                                    </Div>
                                                </Div>
                                            </Div>
                                            <Div className="form-group">

                                                <Div className='row'>
                                                    <Div className='col-12'>
                                                        <Select name='industryType' options={industryTypeSelect} defaultValue="Select Industry Type" />
                                                    </Div>
                                                </Div>
                                            </Div>
                                            <Div className="form-group">

                                                <Div className='row'>
                                                    <Div className='col-12'>
                                                        <Input name='otherContactName' type="text" className="form-control" required placeholder="Other Contact Name" />
                                                    </Div>
                                                </Div>
                                            </Div>
                                            <Div className="form-group">

                                                <Div className='row'>
                                                    <Div className='col-12'>
                                                        <Input name='otherContactPhone' type="text" className="form-control" required placeholder="Other Contact Phone Number" />
                                                    </Div>
                                                </Div>
                                            </Div>
                                            <Div className="form-group">
                                                {OTPVerify ? <FormButton type='submit' className="btn btn-primary submit-btn btn-block"
                                                    disabled={verifyRegister}>
                                                    Register</FormButton> : null}
                                            </Div>
                                            <Div className="form-group">
                                                {errorSubmit ? <span style={{ color: 'red' }}>{errorSubmit}</span>
                                                    : null}
                                            </Div>
                                            <Div className="text-block text-center my-3">
                                                <span className="text-small font-weight-semibold">Already a customer ?</span>
                                                <Link to="/" className="text-black text-small">Login</Link>
                                            </Div>
                                        </Form>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </>
    )

}