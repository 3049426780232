import { useEffect, useState } from "react"
import { getAllCustomer } from "../../http/CustomerHttp";
import Div from "../../components/Div";
import { Pagination, Stack } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Input from "../../components/Input";
import FormButton from "../../components/FormButton";
import EditRenewalCustomerModal from "../../components/EditRenewalCustomerModal";


export default function UpdateCustomer() {

    const [customerList, setCustomerList] = useState([]);
    // const [isAscending, setIsAscending] = useState(true);
    const [modalClicked, setModalClicked] = useState(false)
    const [initailCustomerList, setInitialCustomerList] = useState([])
    // const [googleCustomer, setGoogleCustomer] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = customerList.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(customerList.length / itemsPerPage);

    // Change page
    const paginate = (event, value) => {
        setCurrentPage(value);
    };

    const getAllCustomers = async () => {
        const response = await getAllCustomer()
        console.log(response)
        setCustomerList(response.customer)
        setInitialCustomerList(response.customer)
    }

    useEffect(() => {
        getAllCustomers()
    }, [])

    function handleSearchCustomer(event) {

        const search = event.target.value;
        const filteredList = initailCustomerList.filter((customer) => {

            if ((customer && customer.company_name) || (customer.email)) {

                return customer.company_name.toLowerCase().includes(search.toLowerCase()) ||
                    customer.email.toLowerCase().includes(search.toLowerCase());
            }
            else {
                return false
            }
        });

        setCustomerList(filteredList);

    }

    function handleUpdateCustomer(customer) {
        // console.log(customer)
        setModalClicked({
            'customer_email': customer.email,
            'mobile': customer.mobile,
            'phone': customer.phone,
            'contact_id': customer.contact_id
        })
    }

    return (
        <>
            {modalClicked ? <EditRenewalCustomerModal customer={modalClicked} show={true.toString()} open handleClicked={setModalClicked} /> : null}
            <PageTitle title='Update Customer' />
            <Div className="col-md-12">
                <Div className="page-header-toolbar">

                    <Div className="btn-group toolbar-item" role="group" >
                        <Input type="text" name="email" id="email"
                            onChange={handleSearchCustomer}
                        />
                    </Div>

                    <Div className="filter-wrapper">
                    </Div>
                    {/* <span>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                <BsFilter />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Active')}
                                >
                                    Active
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Rejected')}
                                >
                                    Rejected
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleStatusFilter('Suspended')}
                                >
                                    Suspended
                                </Dropdown.Item>

                            </Dropdown.Menu>

                        </Dropdown>

                    </span> */}
                </Div>
            </Div>

            <Div className="col-md-12 margin_top">
                {currentItems ? <table className="table" border="1">
                    <thead>
                        <tr>
                            <th>Zoho Contact</th>
                            <th>Contact Name</th>
                            <th>Website</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Company Name</th>
                            <th>Phone</th>
                            <th>Mobile</th>
                            <th>Time
                                {/* <button onClick={sortData} variant="primary">
                                    <span className="ml-2">
                                        {isAscending ? '↓' : '↑'}
                                    </span>
                                </button> */}
                            </th>
                            <th>Action

                            </th>
                        </tr>
                    </thead>
                    <tbody id="tableBody">
                        {currentItems.map((customer) =>

                            <tr key={customer.contact_id}>
                                <td>
                                    {customer.contact_id}
                                    {/* <button onClick={()=>handlecustomerClick(customer.customer_id)} className="btn btn-link ">{customer.customer_name}</button> */}
                                </td>
                                <td>{customer.contact_name}</td>
                                <td>{customer.website}</td>
                                <td>{customer.first_name}</td>
                                <td>{customer.last_name}</td>
                                <td>{customer.email}</td>
                                <td>{customer.company_name}</td>
                                <td>{customer.phone}</td>
                                <td>{customer.mobile}</td>
                                <td>{customer.timestamp}</td>
                                <td>
                                    <FormButton type='button' style={{ margin: '2px' }}
                                        onClick={() => handleUpdateCustomer(customer)}
                                        className="btn btn-primary submit-btn btn-block">
                                        Update</FormButton>
                                    {/* {renderActionButtons(domain.domain_name, domain.domain_status, domain.domain_id)} */}
                                </td>
                            </tr>
                        )

                        }
                    </tbody>
                </table> :
                    <>
                        <p style={{ marginTop: '30px' }}>Not Domain Present or Could not fetch Domains</p>
                    </>}

                {currentItems.length > 0 ?
                    <Stack spacing={2}>
                        <Pagination count={totalPages} color="primary" page={currentPage} onChange={paginate}>

                        </Pagination>

                    </Stack> : null
                }
            </Div>

        </>
    )
}