import { createPortal } from "react-dom"
import Div from "./Div"
import Input from "./Input"
import FormButton from "./FormButton"
import Select from "./Select";
import { segments, zones } from "../util/enum";
import { useEffect, useState } from "react";
import { GetAllRenewalManagers } from "../http/RenewalHttp";
import Form from "./Form"
import { inviteRenewalMember, insertMemberTagging } from '../http/RenewalMemberHttp'

export default function AddMemberModal({ handleClicked, ...props }) {

    const [userList, setUserList] = useState([]);
    const [segmentList, setSegmentList] = useState([])
    const [zoneList, setZoneList] = useState([])
    // const [initailUserList, setInitialUserList] = useState([])

    const zone = zones.zone.map((zone) =>

        <option key={zone.id}
            name={zone.id}
            value={zone.id}>
            {zone.value}</option>);

    const state = segments.segment.map((state) =>
        <option key={state.id}
            name={state.id}
            value={state.id}>
            {state.value}</option>
    )



    const getRenewalManager = async () => {
        const response = await GetAllRenewalManagers()
        if (response) {

            const managers = response.manager.map(
                (manager) =>
                    <option key={manager.admin_email}
                        name={manager.admin_email}
                        value={manager.admin_email}
                    >
                        {manager.admin_name}</option>
            )
            setUserList(managers)

            // setInitialUserList(response.manager)
        }

    }
    useEffect(() => {

        getRenewalManager()

    }, []);

    function handleClose() {
        handleClicked(false);
    }

    async function handleInviteTeam(event) {
        event.preventDefault();

        const registerFD = new FormData(event.target)

        const data = Object.fromEntries(registerFD.entries())

        console.log(data)

        const response = await inviteRenewalMember(data, 'menu_101023076786896901')

        const tags = segmentList.join('/') + ',' + zoneList.join('/') + ',' + data.user_status
        console.log(tags)
        const addTagging = await insertMemberTagging(data.adminEmail, tags)

        if (response && addTagging) {
            window.location.reload()
        }

    }

    function handleUpdateSegmentSelect(segment) {
        if (segment !== '' && !segmentList.includes(segment)) {
            setSegmentList(prev => {
                return [...prev, segment]
            })

        }
    }

    function handleSegmentRemove(segment) {
        if (segmentList.includes(segment)) {
            setSegmentList(
                segmentList.filter(item => item !== segment)
            )
        }
    }

    function handleUpdateZoneSelect(zone) {
        if (zone !== '' && !zoneList.includes(zone)) {
            setZoneList(prev => {
                return [...prev, zone]
            })

        }
    }

    function handleZoneRemove(zone) {
        if (zoneList.includes(zone)) {
            setZoneList(
                zoneList.filter(item => item !== zone)
            )
        }
    }

    return <>

        {createPortal(
            <Div className="modal-backdrop">
                <dialog {...props} style={{ zIndex: '1', maxWidth: '75%' }}>

                    <Div className="form-group">
                        Add New Member
                    </Div>
                    <Form onSubmit={handleInviteTeam}>
                        <Div className="row">

                            <Div className="form-group col-6">
                                <Input text='' name="adminName"
                                    //  onInput={(e) => setInputDomain(e.target.value)} 
                                    type="text" className="form-control" placeholder="Enter Member Name" />
                            </Div>

                            <Div className="form-group col-6">
                                <Input text='' name="adminPhoneNo"
                                    //  onInput={(e) => setInputDomain(e.target.value)} 
                                    type="text" className="form-control" placeholder="Enter Member Phone" />
                            </Div>

                        </Div>
                        <Div className="row" >

                            <Div className="form-group col-10">
                                <Input text='' name="adminEmail"
                                    //  onInput={(e) => setInputDomain(e.target.value)} 
                                    type="text" className="form-control" placeholder="Enter Member Email" />

                                <Input text='' name="department"
                                    //  onInput={(e) => setInputDomain(e.target.value)} 
                                    type="text" className="form-control" value="dept_100820177448337413" hidden
                                />
                            </Div>
                        </Div>


                        <Div className='row' style={{
                            backgroundColor: '#D3D3D3', padding: '3%', margin: '10px'
                        }}>
                            <Div className='row'>
                                {segmentList.map((item) => {
                                    return <>
                                        <Div
                                            onClick={() => handleSegmentRemove(item)}
                                            style={{ border: '2px', backgroundColor: 'white', width: 'fit-content', margin: '2px' }}>
                                            {item}
                                        </Div>
                                    </>
                                })}
                            </Div>

                            <Div className="row" style={{ marginTop: '5px' }} >

                                {zoneList.map((item) => {
                                    return <>
                                        <Div
                                            onClick={() => handleZoneRemove(item)}
                                            style={{ border: '2px', backgroundColor: 'white', width: 'fit-content', margin: '2px' }}>
                                            {item}
                                        </Div>
                                    </>
                                })}

                            </Div>


                        </Div>

                        <Div style={{
                            backgroundColor: '#D3D3D3', padding: '3%', margin: '10px'
                        }}>

                            <Div className="row" >

                                <Div className="form-group col-3" >
                                    <Select name='user_segment' options={state} defaultValue="Select Segment"
                                        onChange={(e) => handleUpdateSegmentSelect(e.target.value)}

                                        style={{ width: 'auto' }} />

                                </Div>
                                <Div className="form-group col-1"></Div>
                                <Div className="form-group col-3" >
                                    <Select name='user_zone' options={zone} defaultValue="Select Zone"
                                        onChange={(e) => handleUpdateZoneSelect(e.target.value)}

                                        style={{ width: 'auto' }} />
                                </Div>
                            </Div>
                            <Div className="row">
                                <Div className="form-group col-4">
                                    <Select name='user_status' options={userList} defaultValue="Select Reports To"
                                        // onChange={(e) => handleUpdateSelect(renewal_id, 'segments', e.target.value)}

                                        style={{ width: 'auto' }} />

                                </Div>
                            </Div>

                        </Div>

                        <Div className="form-group">
                            <Div className='row' style={{ padding: '5%' }}>
                                <Div className='col-sm-6'>
                                    <FormButton type='submit' style={{ margin: '2px' }}
                                        // onClick={handleInviteTeam}
                                        className="btn btn-primary submit-btn btn-block">Invite Team</FormButton>
                                </Div>
                                <Div className='col-sm-6'>
                                    <FormButton type='button' onClick={handleClose} style={{ margin: '2px' }} className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                                </Div>
                            </Div>

                            <Div className='row'>
                                <Div className='col-sm-12'>
                                    {/* {error ? <p style={{ color: 'red', marginLeft: '15px', marginTop: '4px' }}> {error}</p> : null} */}
                                </Div>
                            </Div>
                        </Div>
                    </Form>

                </dialog>

            </Div>
            , document.getElementById('modal'))

        }
    </>
}