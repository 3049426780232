import { redirect } from "react-router-dom"

export function checkPasswordMatch(password, confirmPassword) {
    if (password !== confirmPassword) {
        return false
    }
    return true
}

export function checkPhoneNumber(phoneNumber) {
    if (phoneNumber.length < 10) {
        return false
    }
    return true
}

export function validLoader() {

    const email = localStorage.getItem('user');

    if (email) {
        return email
    }
    else {
        return redirect('/')
    }
}

export function validLoginLoader() {

    const email = localStorage.getItem('user');
    const menuList = JSON.parse(menu)
    if (email) {
        return redirect('/home/' + String(menuList[0].to_route))
    }
    else {
        return 0
    }
}

export function validateAdminLoginLoader() {

    const email = localStorage.getItem('admin_user');

    if (email) {
        return redirect('home')
    }
    else {
        return 0
    }
}

export function logoutLoader() {
    if (localStorage.getItem('user') && localStorage.getItem('token') && localStorage.getItem('menu')) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('menu')
    }
    return null
}

export function testLoader() {
    
    const token = JSON.parse(getToken)
    if (token) {

        // console.log(token.exp)
        const tokenExp = new Date(token.exp).getTime()
        if (tokenExp > Date.now()) {
            console.log('Token Valid')
            return null
        } else {
            window.location.href = '/logout'
        }
    } else {
        return null
    }

}

export const getSessionUser = localStorage.getItem('user');

export const getToken = localStorage.getItem('token');

export const menu = localStorage.getItem('menu')
