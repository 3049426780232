import { API_URL } from "../config"
import { getToken } from "../util/validation";

const token = JSON.parse(getToken)

export async function getAllDepartment() {

    try {
        const response = await fetch(API_URL + 'get_all_department/', {
            method: "POST",
            headers: {
                // "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
        })

        if (!response.ok) {
            // throw 

        }
        else {
            return response.json()
        }
    } catch (error) {
        return false
    }
}

export async function addDepartment(department_name) {

    try {

        const email = localStorage.getItem('user');

        var data = {
            'email': email,
            'department_name': department_name
        }

        const response = await fetch(API_URL + '/adminpanel/add_department/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token,
            },
            body: JSON.stringify(data),
        })

        if (!response.ok) {
            return false
        }
        else {
            return response
        }
    } catch (error) {
        return false
    }
}