import { useState, useRef, useEffect } from "react";
import PageTitle from '../../components/PageTitle'
import Div from "../../components/Div";
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Dropdown } from 'react-bootstrap'
import EditAdminModal from "../../components/EditAdminModal";
import Input from "../../components/Input";
import { getAllAdmin } from "../../http/AdminVerifyHttp";
import { Pagination, Stack } from "@mui/material";

export default function GetAllAdmin() {

    const [adminList, setAdminList] = useState([]);
    const [modalClicked, handleModalClicked] = useState(false)

    const initialAdminListRef = useRef([]); // Use useRef to store the initial list

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = adminList.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(adminList.length / itemsPerPage);

    // Change page
    const paginate = (event, value) => {
        setCurrentPage(value);
      };


    const getAdmin = async () => {
        const response = await getAllAdmin()
        if(response){
            setAdminList(response.admins)
            initialAdminListRef.current = response.admins; // Store the initial list in the ref
        }
        else{
            return 
        }

    }

    // Call getAdmin only once on component mount
    useEffect(() => {
        getAdmin();
    }, []);

    function editAdminModal(admin) {
        handleModalClicked(admin)
    }

    function handleSearchAdmin(event) {
        const search = event.target.value;

        const filteredList = initialAdminListRef.current.filter((admin) => {
            if (admin && admin.admin_email) {
                return admin.admin_email.toLowerCase().includes(search.toLowerCase());
            } else {
                return false;
            }
        });

        setAdminList(filteredList);
    }

    const renderActionButtons = (admin) => {
        return (
            <Div className="d-flex justify-content-center">
                <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                        <BsThreeDotsVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => editAdminModal(admin)} >
                            Edit
                        </Dropdown.Item>
                    </Dropdown.Menu>

                </Dropdown>
            </Div>
        );
    };

    return (
        <>
            <PageTitle title='Get All Admin' />

            {modalClicked ? <EditAdminModal updateState={getAdmin} show={true.toString()} open admin={modalClicked} handleClicked={handleModalClicked} /> : null}

            <Div className="col-md-12">
                <Div className="page-header-toolbar">

                    <Div className="btn-group toolbar-item" role="group" >
                        <Input type="text" name="email" id="email" onChange={handleSearchAdmin} />
                    </Div>
                    
                </Div>
            </Div>

            <Div className="col-md-12 margin_top">
                {adminList.length > 0 ? <table className="table" border="1">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Action

                            </th>
                        </tr>
                    </thead>
                    <tbody id="tableBody">
                        {currentItems.map((admin) =>

                            <tr key={admin.admin_id}>
                                <td>{admin.admin_name}</td>
                                <td>{admin.admin_email}</td>
                                <td>{admin.phone_number}</td>
                                <td>
                                    {renderActionButtons(admin)}
                                </td>
                            </tr>
                        )

                        }
                    </tbody>
                </table>:
                    <>
                        <p style={{marginTop:'30px'}}>No Admin Present</p>
                    </>}

                {adminList.length > 0 ?
                    <Stack spacing={2}>
                        <Pagination count={totalPages} color="primary" page={currentPage} onChange={paginate}>

                        </Pagination>

                    </Stack>: null
                }
            </Div>
        </>
    )
}
