import { createPortal } from "react-dom"
import Div from "./Div"
import Form from "./Form"
import FormButton from "./FormButton"
import Select from "./Select"
import { highlight, comment_status, aggrement_status, yesno } from "../util/enum"
import Input from "./Input"
import { useState } from "react"
import { insertComments } from "../http/RenewalHttp"

export default function AddCommentModal({ comments, handleClicked, ...props }) {

    const [confirmOption, setConfirmOption] = useState(false)
    // const [licenseAdded, setLicenseAdded] = useState(0)

    const status = aggrement_status.status.map((highlight) =>
        highlight.id !== comments ?
            <option key={highlight.id}
                name={highlight.id}
                value={highlight.id}>
                {highlight.value}</option>
            : null);


    const highlights = highlight.comment.map((highlight) =>
        highlight.id !== comments ?
            <option key={highlight.id}
                name={highlight.id}
                value={highlight.id}>
                {highlight.value}</option>
            : null);

    const comments_status = comment_status.status.map((highlight) =>
        highlight.id !== comments ?
            <option key={highlight.id}
                name={highlight.id}
                value={highlight.value}>
                {highlight.value}</option>
            : null);



    const options = yesno.options.map((highlight) =>
        highlight.id !== comments ?
            <option key={highlight.id}
                name={highlight.id}
                value={highlight.id}>
                {highlight.value}</option>
            : null);

            

    function handleClose() {
        handleClicked(false)
    }

    function handleLicenseAdd(license) {
        // setLicenseAdded(license)
        console.log(typeof (license))

    }
    const handleUpdateSelect = async (renewal_id, field, value) => {

        if (field === 'highlight') {
            // updatehighlight
        }
        else if (field === 'status') {
            // update status 
            if (value === 'Confirmation Received' || value === 'Partial Upgrade' || value === 'Mid Term Upgrade' || value === 'Confirmation received for flex') {
                console.log(value)
                const addedStatus = <>
                    <Div className='row'>
                        <label>Plan Name</label>
                        <Div className="form-group">
                            <Input name='com_plan_name'
                                onChange={(e) => handleLicenseAdd(e.target.value)}
                                // value={licenseAdded}
                                type="text" className="form-control" placeholder="Enter Plan Name" />
                        </Div>

                    </Div>

                    <Div className='row'>
                        <label>Next Renewal Date</label>
                        <Div className="form-group">
                            <Input name='com_next_renewal_date'
                                onChange={(e) => handleLicenseAdd(e.target.value)}
                                // value={licenseAdded}
                                type="date" className="form-control" placeholder="Enter Next Renewal Date" />
                        </Div>

                    </Div>

                    <Div className='row'>
                        <label>No . of License</label>
                        <Div className="form-group">
                            <Input name='add_lic'
                                onChange={(e) => handleLicenseAdd(e.target.value)}
                                // value={licenseAdded}
                                type="text" className="form-control" placeholder="Enter No. of License" />
                        </Div>

                    </Div>

                    <Div className='row'>
                        <label>Task Given</label>
                        <Div className="form-group">
                            <Select name='task_status' options={options} defaultValue={comments.zone}
                                // onChange={(e) => handleUpdateSelect(comments.renewal_id, 'task_given', e.target.value)}

                                style={{ width: 'auto' }} />
                        </Div>

                    </Div>


                    {/* {licenseAdded > 100 ?  */}
                    <Div className='row'>
                        <label>Aggrement Status</label>
                        <Div className="form-group">
                            <Select name='aggrement' options={status} defaultValue={comments.zone}
                                // onChange={(e) => handleUpdateSelect(comments.renewal_id, 'status', e.target.value)}

                                style={{ width: 'auto' }} />
                        </Div>
                    </Div>

                    <Div className='row'>
                        <label>Price</label>
                        <Div className="form-group">
                            <Input text='' name='price'
                                // onChange={(e) => setLicenseAdded(e.target.value)}
                                type="text" className="form-control" placeholder="Enter Price" />
                        </Div>
                    </Div>

                </>

                setConfirmOption(addedStatus)
            }
            else if (value === 'Extension') {
                const addedStatus = <>
                    <Div className='row'>
                        <label>Extension Date</label>
                        <Div className="form-group">
                            <Input text='' name='extension_date'
                                // onChange={(e) => setLicenseAdded(e.target.value)}
                                type="date" className="form-control" placeholder="Enter Reason" />
                        </Div>
                    </Div>
                </>

                setConfirmOption(addedStatus)
            }
            else if (value === 'Lost') {
                const addedStatus = <>
                    <Div className='row'>
                        <label>Reason</label>
                        <Div className="form-group">
                            <Input text='' name='reason'
                                // onChange={(e) => setLicenseAdded(e.target.value)}
                                type="text" className="form-control" placeholder="Enter Reason" />
                        </Div>
                    </Div>
                </>

                setConfirmOption(addedStatus)
            }
            else {
                setConfirmOption(false)
            }

        }
    };


    function handleSubmit(event) {
        event.preventDefault()
        const forgetFD = new FormData(event.target)
        const data = Object.fromEntries(forgetFD.entries())
        console.log(data)
        if (data.highlight === '') {
            alert('Highlight cannot be Blank')
            return false
        }
        if (data.status === '') {
            alert('Status cannot be Blank')
            return false
        }
        console.log(data.status)
        if (data.status === 'Confirmation Received'
            || data.status === 'Partial Upgrade'
            || data.status === 'Mid Term Upgrade'
            || data.status === 'Confirmation received for flex') {

            if(data.add_lic === ''){
                alert('Enter License Added')
                return false
            }
               
            if (parseInt(data.add_lic) > 99 && data.aggrement === '') {
                alert('Aggrement cannot be Blank for more than 100 license')
                return false
            }
        }
        if(data.status === 'Lost'){
            if(data.reason === ''){
                alert('Enter Reason')
                return false
            }
        }
        if(data.status === 'Extension'){
            if(data.extension_date === ''){
                alert('Enter Extension Date')
                return false
            }
        }
        console.log(data)
        const response = insertComments(data, comments)
        console.log(response)
        if(response){
            window.location.reload()
        }

    }

    return (
        <>
            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1', maxWidth: '100%' }}>

                        <Div className="form-group">
                            Add Comments
                        </Div>
                        <Form onSubmit={handleSubmit}>
                            <Div className="form-group">

                                <Div className='row'>
                                    <label>Highlighted Case</label>
                                    <Div className="form-group">
                                        <Select name='highlight' options={highlights} defaultValue={comments.zone}
                                            onChange={(e) => handleUpdateSelect(comments.renewal_id, 'highlight', e.target.value)}

                                            style={{ width: 'auto' }} />
                                    </Div>

                                </Div>

                                <Div className='row'>
                                    <label>Status</label>
                                    <Div className="form-group">
                                        <Select name='status' options={comments_status} defaultValue={comments.zone}
                                            onChange={(e) => handleUpdateSelect(comments.renewal_id, 'status', e.target.value)}

                                            style={{ width: 'auto' }} />
                                    </Div>

                                </Div>

                                {confirmOption}

                                <Div className='row'>

                                    <Div className='col-sm-6'>
                                        <FormButton type='submit' style={{ margin: '2px' }}
                                            className="btn btn-primary submit-btn btn-block" >Add Comment</FormButton>
                                    </Div>
                                    <Div className='col-sm-6'>
                                        <FormButton type='button' style={{ margin: '2px' }} onClick={handleClose}
                                            className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                                    </Div>
                                </Div>

                            </Div>

                        </Form>
                    </dialog>

                </Div>
                , document.getElementById('modal'))

            }

        </>
    )
}