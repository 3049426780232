import { createPortal } from "react-dom"
import Div from "./Div"
import Form from "./Form"
import Input from "./Input"
import FormButton from "./FormButton"
import { useState } from "react"
import { updateRenewalCustomer } from "../http/CustomerHttp"


export default function EditRenewalCustomerModal({ customer, handleClicked, ...props }) {

    const [updatedCustomer, setUpdatedCustomer] = useState(customer);

    function handleClose() {
        handleClicked(false)

    }

    function handleChangeInput(event, field) {
        setUpdatedCustomer(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    }

    async function handleUpdateCustomer(event) {
        event.preventDefault();
        // const statusFD = new FormData(event.target)

        // const data = Object.fromEntries(statusFD.entries())
        if ((!updatedCustomer)) {

            handleClicked(false)
        }
        else {
            console.log(updatedCustomer)
            const response = await updateRenewalCustomer(updatedCustomer)
            console.log(response)
            if (!response.error) {
                alert('Data Updated Sucessfully')
                handleClicked(false);
                window.location.reload();
            }
            else {
                alert(response.message)
            }
        }
    }

    return (
        <>
            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1', maxWidth: '75%' }}>

                        <Div className="form-group">
                            Edit Customer
                        </Div>
                        <Form onSubmit={handleUpdateCustomer}>
                            <Div className="form-group">
                                <Input text=''
                                    type="text" onChange={(event) => handleChangeInput(event, 'customer_email')}
                                     className="form-control" placeholder="Enter Customer Email" value={updatedCustomer.customer_email} />
                            </Div>

                            <Div className="form-group">
                            <Input text=''
                                    type="text" onChange={(event) => handleChangeInput(event, 'phone')}
                                     className="form-control" placeholder="Enter Phone Number" value={updatedCustomer.phone} />
                            </Div>

                            <Div className="form-group">
                            <Input text=''
                                    type="text" onChange={(event) => handleChangeInput(event, 'mobile')}
                                     className="form-control" placeholder="Enter Mobile NUmber" value={updatedCustomer.mobile} />
                            </Div>

                            <Div className="form-group">
                                <Div className='row'>
                                    <Div className='col-sm-6'>
                                        <FormButton type='submit' style={{ margin: '2px' }} className="btn btn-primary submit-btn btn-block">Edit Customer</FormButton>
                                    </Div>
                                    <Div className='col-sm-6'>
                                        <FormButton type='button' style={{ margin: '2px' }} onClick={handleClose} className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                                    </Div>
                                </Div>

                                <Div className='row'>
                                    <Div className='col-sm-12'>
                                        {/* <p style={{color:'red',marginLeft: '15px'}}> OTP Does Not Match</p>  */}
                                    </Div>
                                </Div>
                            </Div>
                        </Form>
                    </dialog>

                </Div>
                , document.getElementById('modal'))

            }
        </>
    )
}