import Div from "./Div"
import FormButton from "./FormButton"
import { createPortal } from "react-dom"
import Input from "./Input";
import Form from "./Form";
import { useState } from "react";
import { addGoogleLicense } from "../http/AddLicenseHttp";


export function GoogleAddLicenseModal({ updateState, domain_name, googleLicense, handleClicked, ...props }) {

    const [seats, setSeats] = useState(googleLicense.seats);
    const [error, setError] = useState();

    function handleClose() {
        handleClicked(false);
    }

    function handleChangeSeats(event) {
       setSeats(event.target.value)
    }

    async function handleGoogleAddLicense(event){
        event.preventDefault();

        if(seats < googleLicense.seats){
            setError('Seat Reduction not Allowed')
        }
        else{
            const googleAddLicenseResponse =  await addGoogleLicense(googleLicense.subscription_id, googleLicense.customer_id,seats, googleLicense.plan_name)
            console.log(googleAddLicenseResponse)
            console.log(googleLicense.subscription_id, googleLicense.customer_id,seats, googleLicense.plan_name)
        }
    }

    return (
        <>
            {createPortal(

                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1', width:'45%' }}>

                        <Div className="form-group">
                            Add License for {domain_name}

                        </Div>
                        <Div>
                            <Form onSubmit={handleGoogleAddLicense}>
                                <Div className="form-group">
                                    <Input value={googleLicense.subscription_id} type="text" className="form-control" disabled />
                                </Div>
                                <Div className="form-group">
                                    <Input value={googleLicense.customer_id} type="text" className="form-control" disabled />
                                </Div>
                                <Div className="form-group">
                                    <Input value={seats} type="text" name='seats' className="form-control" onChange={handleChangeSeats} />
                                </Div>
                                <Div className='row'>
                                    <Div className='col-sm-6'>
                                        < FormButton type='submit' className="btn btn-primary submit-btn btn-block" >
                                            Add License </FormButton>
                                    </Div>
                                    <Div className='col-sm-6'>
                                        < FormButton type='button' onClick={handleClose} className="btn btn-primary submit-btn btn-block" >Close</FormButton>
                                    </Div>
                                    {error ? <p style={{color:'red'}}>{error}</p>: null}
                                </Div>
                            </Form>

                        </Div>
                        {/* {googleLicense !== false ?


                            googleLicense.domains.subscriptions.map((subscription) =>
                                <>
                                    <tr key={subscription.subscriptionId}>
                                        <td>{subscription.skuName}</td>
                                        <td>{subscription.plan.planName}</td>
                                        <td>{subscription.status}</td>
                                        {subscription.plan.planName !== 'FREE' ?
                                            <>
                                                <td>{subscription.seats.licensedNumberOfSeats}</td>
                                                <td>{subscription.seats.maximumNumberOfSeats}</td>
                                            </>
                                            : <td></td>}
                                        {subscription.plan.planName !== 'FLEXIBLE' ?
                                            <td>{subscription.seats.numberOfSeats}</td> :
                                            <td></td>
                                        }
                                        <td>
                                            <button onClick={() => handleGoogleAddLicense(subscription)}>Add License</button>
                                        </td>
                                    </tr>

                                </>
                            ) : null} */}

                    </dialog >

                </Div >
                , document.getElementById('modal'))

            }
        </>
    )

}