export default function Select({name, options, defaultValue, ...props}) {

    return (
        <>
            <select name={name} className='form-control' {...props} >
                <option value=''>{defaultValue}</option>
                {options}
            </select>
        </>
    )
}