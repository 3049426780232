import Form from "../../components/Form"
import Div from "../../components/Div"
import Input from "../../components/Input"
import FormButton from "../../components/FormButton"
import PageTitle from "../../components/PageTitle"
import Spinner from "../../components/Spinner"
import { useState } from "react"
import { createZohoTask } from "../../http/ZohoHttp"

export default function AddZohoTask() {

    const [isLoading, setIsLoading] = useState(false)

    async function handleSubmit(event){
        setIsLoading(true)
        event.preventDefault();
        const fd = new FormData(event.target)
        const data = Object.fromEntries(fd.entries())
        console.log(data)
        const zohoResponse = await createZohoTask(data)
        console.log(zohoResponse)
        setIsLoading(false)
    }

    return (
        <>

            <PageTitle title='Create Zoho Task for Account Department' />

            {isLoading ? <Spinner /> : null}
            <Form onSubmit ={handleSubmit}>
                <Div className="mb-3">
                    <label  className="form-label">Enter Subject</label>
                    <Input type="text" className="form-control" name="subject" id="subject" placeholder="" />

                </Div>
                <Div className="mb-3">
                    <label  className="form-label">Enter Description</label>
                    <textarea className="form-control" name="description" id="description" rows="3"></textarea>
                </Div>
                <FormButton type="submit" className="btn btn-primary">Submit</FormButton>
            </Form>

        </>
    )
}