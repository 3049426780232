import Div from "../../components/Div"
import Form from "../../components/Form"
import Header from "../../components/Header"
import Input from "../../components/Input"
import FormButton from "../../components/FormButton"
import { checkPasswordMatch } from '../../util/validation.js'
import { useState } from "react"
import {resetAdminPassword} from '../../http/AdminVerifyHttp.js'
import { getSessionUser } from "../../util/validation.js"

export default function AdminResetPassword() {

    // Hangdle Error Message State
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('')

    
    //Handle Submit State
    const [errorSubmit, handleErrorSubmit] = useState(false)

    async function handleSubmit(event) {

        event.preventDefault();

        const resetFD = new FormData(event.target)

        const data = Object.fromEntries(resetFD.entries())

        if (!checkPasswordMatch(data.password, data.confirmPassword)) {
            setConfirmPasswordErrorMessage('Password Does not Match !')
            return
        }
        setConfirmPasswordErrorMessage('')

        const registerResponse = await resetAdminPassword(data)

        if (registerResponse){
            window.location.href = '/adminpanel'
        }
        else{
            handleErrorSubmit('Something went wrong')
        }
    }

    return (
        <>
            <Div className="container-scroller">
                <Div className="container-fluid page-body-wrapper full-page-wrapper">
                    <Div className="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
                        <Div className="row w-100">
                            <Div className="col-lg-4 mx-auto text-center">
                                <Header title='Reset Your Password' />

                                <p style={{ color: 'white' }}>Enter your email address and an OTP will be emailed to you.</p>
                                <Div className="auto-form-wrapper">
                                    <Form onSubmit={handleSubmit}>
                                        <Div className="form-group">
                                            <Input type="email" className="form-control" name='email' value={getSessionUser} placeholder="Email"  />
                                        </Div>
                                        <Div className="form-group">
                                            <Input name='password' type="password" className="form-control" required placeholder="Password" />
                                        </Div>
                                        <Div className="form-group">
                                            <Input name='confirmPassword' error={confirmPasswordErrorMessage} type="password" className="form-control" required placeholder="Confirm Password" />
                                        </Div>
                                        <Div className="form-group">
                                            <FormButton className="btn btn-primary submit-btn btn-block">Reset Password</FormButton>
                                        </Div>
                                        <Div className="form-group">
                                            {errorSubmit ? <span style={{ color: 'red' }}>{errorSubmit}</span>
                                                : null}
                                        </Div>
                                    </Form>
                                </Div>

                            </Div>

                        </Div>
                    </Div>
                </Div>
            </Div>
        </>
    )
}