import Div from "./Div"
import Form from "./Form"
import { createPortal } from "react-dom"
import { useState, useEffect } from "react"
import { getAllMenuItem, getUserMenuItem, updateUserMenuItem } from "../http/MenuHttp"
import FormButton from "./FormButton"


export default function UserEditDelegationModal({handleClicked, user, buttonText, setHttpSubmit, ...props}) {

    const [menuItem, setMenu] = useState()
    const [selectList, setSelectList] = useState(new Set())
    const [initialUserMenu, setInitialUserMenu] = useState()

    useEffect(() => {
        const getMenu = async () => {
            try {
                const menuResponse = await getAllMenuItem();
                const userMenu = await getUserMenuItem(user.user_email);
                setMenu(menuResponse.menu);
                const userJson = await userMenu.json()
                setInitialUserMenu(userJson.menu)
                const menu = userJson.menu.map((menu)=> menu.menu_name)
                
                setSelectList(menu)
                
                

            } catch (error) {
                window.alert("Failed to fetch Menu data. Please try again later.");
            }
        };

        getMenu()
    },[user])

    function handleMenuClick(menu_name) {

        setSelectList((prev) => {
            const newClickedItems = new Set(prev);
            if (newClickedItems.has(menu_name)) {
                newClickedItems.delete(menu_name);
            } else {
                newClickedItems.add(menu_name);
            }
            
            return newClickedItems;
        })
    }

    function handleRemoveList(list) {
        setSelectList((prev) => {
            const newClickedItems = new Set(prev);
            if (newClickedItems.has(list)) {
                newClickedItems.delete(list)
            }
            return newClickedItems;
        });
    }

    function handleSubmit(event) {
        event.preventDefault();

        const filterList = menuItem.filter((menu)=> {
            if(selectList.has(menu.menu_name)){
                return menu
            }else{
                return false
            }
            })

        const menuIds = filterList.map((menu) => menu.menu_id)

        updateUserMenuItem(user.user_id, menuIds, initialUserMenu)
        // insertUserMenu(menuIds, setHttpSubmit.user_id)
        handleClicked(false)
        
    }


    return (
        <>

            {createPortal(
                <Div className="modal-backdrop">
                    <dialog {...props} style={{ zIndex: '1', width: '70%' }}>

                        <Div className="form-group">
                            Edit User Delegation
                        </Div>
                        <Div className="col-lg-12 grid-margin stretch-card">
                            <Div className="card">
                                <Div className="card-body">

                                    <Form className="form-sample" onSubmit={handleSubmit}>
                                        <Div className="row">

                                            <Div
                                                className="scroll-container"
                                                style={{
                                                    overflowY: "auto",
                                                    height: "350px",
                                                    width: "30%",
                                                    border: "1px solid #000",
                                                    padding: "10px",
                                                    marginLeft: '12%'
                                                }}
                                            >
                                                <Div
                                                    className="modules"
                                                    style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        justifyContent: "center",
                                                        gap: "10px",
                                                        marginBottom: "20px",
                                                        width: "60%",
                                                        margin: "auto", // Center the .modules container
                                                    }}
                                                >
                                                    {menuItem ? menuItem.map((menu) =>
                                                        <Div
                                                            key={menu.menu_id}
                                                            className="module"
                                                            style={{
                                                                border: `3px solid "#000"`,
                                                                padding: "10px",
                                                                textAlign: "center",
                                                                cursor: "pointer",
                                                                minWidth: "100px",
                                                                gap: "5px",
                                                                // color: clickedMenu.has(menu.menu_id) ? "green" : "inherit",
                                                                borderRadius: "30px", // Add this line to make the box rounded
                                                            }}
                                                            onClick={() => handleMenuClick(menu.menu_name)}
                                                        >
                                                            {menu.menu_name}
                                                        </Div>

                                                    ) :
                                                        <Div>Loading.....</Div>}
                                                </Div>
                                            </Div>

                                            <Div
                                                className="scroll-container"
                                                style={{
                                                    overflowY: "auto",
                                                    height: "350px",
                                                    width: "30%",
                                                    border: "1px solid #000",
                                                    padding: "10px",
                                                    marginLeft: '17%'
                                                }}
                                            >
                                                <Div
                                                    className="modules"
                                                    style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        justifyContent: "center",
                                                        gap: "10px",
                                                        marginBottom: "20px",
                                                        width: "80%",
                                                        margin: "auto", // Center the .modules container
                                                    }}
                                                >
                                                    <Div
                                                        className="module"
                                                        style={{
                                                            border: `3px solid "#000"`,
                                                            padding: "10px",
                                                            textAlign: "center",
                                                            cursor: "pointer",
                                                            minWidth: "100px",
                                                            gap: "5px",
                                                            borderRadius: "30px", // Add this line to make the box rounded
                                                        }}
                                                    >
                                                        {selectList ? [...selectList].map((list, index) =>
                                                            <Div key={index}
                                                                style={{
                                                                    border: `3px solid "#000"`,
                                                                    padding: "10px",
                                                                    textAlign: "center",
                                                                    cursor: "pointer",
                                                                    minWidth: "100px",
                                                                    gap: "5px",
                                                                    // color: clickedMenu.has(menu.menu_id) ? "green" : "inherit",
                                                                    borderRadius: "30px", // Add this line to make the box rounded
                                                                }}
                                                                onClick={() => handleRemoveList(list)}>{list}</Div>
                                                        ) : null}
                                                    </Div>

                                                </Div>
                                            </Div>
                                        </Div>
                                        <br></br>
                                        <FormButton type="submit" className="btn btn-primary">
                                            {buttonText}
                                        </FormButton>

                                        <FormButton type='button' onClick={() => handleClicked(false)} style={{ marginLeft: '1%' }} className="btn btn-primary">
                                            Close
                                        </FormButton>
                                    </Form>
                                </Div>
                            </Div>
                        </Div>
                    </dialog>
                </Div>
                , document.getElementById('modal'))
            }

        </>
    )
}