import { API_URL } from "../config"
import { getToken } from "../util/validation"

const token = JSON.parse(getToken)

export async function getAllMenuItem() {
    try {

        const response = await fetch(API_URL + 'adminpanel/get_all_menu_item/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token.login_token
            },
        })

        if (!response.ok) {
            return false
        }else {
            return response.json()
        }
    } catch (error) {
        return false
    } 
}

export async function getAdminMenuItem(email) {


    var data = {
        'email': email
    }
    const response = await fetch(API_URL + '/adminpanel/get_admin_menu_item/', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.login_token
        },
        body: JSON.stringify(data),
    })

    if (!response.ok) {
        // throw 

    }
    else {
        return response
    }
}

export async function insertUserMenu(menu_id, user_id) {
    const email = localStorage.getItem('user');

    var data = {
        'email': email,
        'menu_id': menu_id,
        'user_id': user_id
    }
    const response = await fetch(API_URL + '/adminpanel/insert_user_menu/', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.login_token
        },
        body: JSON.stringify(data),
    })

    if (!response.ok) {
        // throw 
        console.log(response)
    }
    else {
        return response
    }
}

export async function getUserMenuItem(email) {


    var data = {
        'email': email
    }

    const response = await fetch(API_URL + 'get_menu_item/', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.login_token,
        },
        body: JSON.stringify(data),
    })

    if (!response.ok) {
        // throw 
        console.log(response)
    }
    else {
        return response
    }
}

export async function updateUserMenuItem(user_id, menuList, initialUserMenu) {

    const email = localStorage.getItem('user');

    var data = {
        'email': email,
        'menu_id': menuList,
        'user_id': user_id,
        'initialUserMenu': initialUserMenu
    }
    const response = await fetch(API_URL + '/adminpanel/update_user_menu/', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.login_token,
        },
        body: JSON.stringify(data),
    })

    if (!response.ok) {
        // throw 
        console.log(response)
    }
    else {
        return response
    }
}

export async function updateAdminMenuItem(admin_id, menuList, initialUserMenu) {

    const email = localStorage.getItem('user');

    var data = {
        'email': email,
        'menu_id': menuList,
        'initialAdminMenu': initialUserMenu,
        'admin_id': admin_id,

    }
    const response = await fetch(API_URL + '/adminpanel/update_admin_menu/', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token.login_token,
        },
        body: JSON.stringify(data),
    })

    if (!response.ok) {
        // throw 

    }
    else {
        return response
    }
}